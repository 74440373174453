import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";

const CustomerAlias = (props) => {
  console.log("Inside CustomerAlias props", props);
  const address = props.address;
  console.log("Inside CustomerAlias address", address);

  const [nameState, setNameState] = useState({
    name: address.name,
    alias: address.alias,
    mapNo: address.mapNo,
    salesShipmentNum: address.salesShipmentNum,
  });

  console.log("After Init addressState ", nameState);

  const changeHandler = (props) => {
    console.log("Inside CustomerAlias  changeHandler props.target.value", props.target.value);
    console.log("Inside CustomerAlias changeHandler props.target.name", props.target.name);
    const newValue = props.target.value;
    if (props.target.name === "name") {
      console.log("Inside CustomerAlias changeHandler ignoring the edit props.target.value", props.target.value);
      return false;
    }
    setNameState({
      ...nameState,
      [props.target.name]: newValue,
    });
  };

  const UpdateButtonHandler = () => {
    if (nameState.name === "" || nameState.alias === "") {
      alert("No value provided for the Alias Name");
      return false;
    } else {
      props.callBack({ action: "update", address: nameState });
      //setTextArea("");
    }
  };

  return (
    <Modal
      centered
      show={props.show}
      onHide={() => {
        props.callBack({ action: "cancel" });
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {"Customer ID : "} {nameState.mapNo}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="col">
                <div className="input-group input-group-sm mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">
                      Name
                    </span>
                  </div>
                  <input
                    type="text"
                    name="name"
                    value={nameState.name}
                    onChange={changeHandler}
                    className="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-sm"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="col">
                <div className="input-group input-group-sm mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">
                      Alias Name
                    </span>
                  </div>

                  <input
                    type="text"
                    name="alias"
                    value={nameState.alias}
                    onChange={changeHandler}
                    className="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-sm"
                  />
                  {/* <input type="text" name="place" value={addressState.place} onChange={changeHandler} className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="text-left">
                <button
                  className="btn btn-dark btn-sm"
                  onClick={() => {
                    props.callBack({ action: "cancel" });
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>

            <div className="col">
              <div>
                <button className="btn btn-secondary btn-sm float-right" onClick={UpdateButtonHandler}>
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
export default CustomerAlias;
