import React, { useState } from "react";
import { CLICK_ACTION } from "../AppConstant/ShippingConstants";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import { Fragment } from "react";

const DimensionModal = (props) => {
  console.log("Inside DimensionModal props", props);
  const [dimensionState, setDimensionState] = useState(props.dimension);
  console.log("Inside DimensionModal setting  dimensionState ", dimensionState);
  const changeHandler = (props) => {
    console.log("DimensionModalchangeHandler props.target.value", props.target.value);
    console.log("DimensionModal props.target.name", props.target.name);
    const newValue = props.target.value.trim();
    setDimensionState({
      ...dimensionState,
      [props.target.name]: newValue,
    });
  };

  const UpdateButtonHandler = () => {
    if (!isValidFields()) {
      alert("Please fill all the fields");
      return false;
    } else {
      console.log("DimensionModal updated State", dimensionState);
      props.callBack({
        action: CLICK_ACTION.UPDATE,
        dimension: dimensionState,
      });

      setDimensionState({
        weight: "",
        type: "",
        salesShipmentNum: "",
      });
    }
  };

  const isValidFields = () => {
    switch (props.dimension.type) {
      case "weight":
        return dimensionState.weight !== "";
      case "size":
        return !(dimensionState.length === "" || dimensionState.height === "" || dimensionState.width === "");
    }
  };

  return (
    <Modal
      centered
      show={props.show}
      onHide={() => {
        props.callBack({ action: "cancel" });
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.title} </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="container">
          {props.dimension.type === "weight" && (
            <Fragment>
              <div className="row">
                <div className="col">
                  <div className="col">
                    <div className="input-group input-group-sm mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="inputGroup-sizing-sm">
                          Weight
                        </span>
                      </div>
                      <input
                        type="text"
                        name="weight"
                        value={dimensionState.weight}
                        onChange={changeHandler}
                        className="form-control"
                        aria-label="Sizing example input"
                        aria-describedby="inputGroup-sizing-sm"
                      />
                      <div className="input-group-append">
                        <span className="input-group-text" id="inputGroup-sizing-sm">
                          gram
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          )}

          {props.dimension.type === "size" && (
            <Fragment>
              <div className="row">
                <div className="col">
                  <div className="col">
                    <div className="input-group input-group-sm mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="inputGroup-sizing-sm">
                          Length
                        </span>
                      </div>
                      <input
                        type="text"
                        name="length"
                        value={dimensionState.length}
                        onChange={changeHandler}
                        className="form-control"
                        aria-label="Sizing example input"
                        aria-describedby="inputGroup-sizing-sm"
                      />
                      <div className="input-group-append">
                        <span className="input-group-text" id="inputGroup-sizing-sm">
                          centimeters
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <div className="col">
                    <div className="input-group input-group-sm mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="inputGroup-sizing-sm">
                          Width
                        </span>
                      </div>
                      <input
                        type="text"
                        name="width"
                        value={dimensionState.width}
                        onChange={changeHandler}
                        className="form-control"
                        aria-label="Sizing example input"
                        aria-describedby="inputGroup-sizing-sm"
                      />
                      <div className="input-group-append">
                        <span className="input-group-text" id="inputGroup-sizing-sm">
                          centimeters
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="col">
                    <div className="input-group input-group-sm mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="inputGroup-sizing-sm">
                          Height
                        </span>
                      </div>
                      <input
                        type="text"
                        name="height"
                        value={dimensionState.height}
                        onChange={changeHandler}
                        className="form-control"
                        aria-label="Sizing example input"
                        aria-describedby="inputGroup-sizing-sm"
                      />
                      <div className="input-group-append">
                        <span className="input-group-text" id="inputGroup-sizing-sm">
                          centimeters
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="text-left">
                <button
                  className="btn btn-dark btn-sm"
                  onClick={() => {
                    props.callBack({ action: "cancel" });
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>

            <div className="col">
              <div>
                <button className="btn btn-secondary btn-sm float-right" onClick={UpdateButtonHandler}>
                  Edit
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
export default DimensionModal;
