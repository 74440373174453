export const GetCount = (list, fieldName) => {
  const filteredOrders = list.filter(
    (order) => typeof order.courierName !== "undefined" && order.courierName.toLowerCase().search(fieldName) > -1,
  );
  return filteredOrders.length;
};

//select the orders whose isSelected flag true
export const GetisSelectedTrueOrders = (shipmentOrders) => {
  try {
    console.log("GetisSelectedTrueOrders  shipmentOrders", shipmentOrders);
    const selectedOrders = shipmentOrders.filter((order) => order.isSelected === true && order.trackingNumber === null);
    console.log("GetisSelectedTrueOrders ", selectedOrders);
    return selectedOrders;
  } catch (exception) {
    console.log(" ListHelper GetisSelectedTrueOrders  exceptipon ", exception);
  }
};
