import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";

const AddressContainer = (props) => {
  const [addressState] = useState({
    toStreet: props.toStreet,
    toCity: props.toCity,
    toState: props.toState,
    toPostCode: props.toPostcode,
    salesShipmentNum: props.salesShipmentNum,
  });
  let validationError = "";
  const AddressValidation = (props) => {
    validationError = "";

    if (props.shipmentPriceError != null) {
      validationError = "Fix the auspost error in status";
      return "Fix the auspost error in status";
    } else if (props.toStreet === "") {
      validationError = "Street is empty";
      return "Street is empty";
    } else if (props.toCity === "") {
      validationError = "City is empty";
      return "City is empty";
    } else if (props.toState === "") {
      validationError = "State is empty";
      return "State is empty";
    } else if (props.toPostcode === "") {
      validationError = "Postcode is empty";
      return "Postcode is empty ";
    } else {
      return "";
    }
  };
  //console.log('AddressDiv addressState :  ',addressState);
  const editClickCall = () => {
    props.shipmentGridCallBack(addressState);
  };

  return (
    <div>
      {props.toStreet + ", " + props.toCity + ", " + props.toState + ", " + props.toPostcode}
      <FontAwesomeIcon
        onClick={editClickCall}
        id="editName"
        icon={faPencilAlt}
        size="1x"
        title="Edit Address"
        color="red"
        style={{ marginLeft: 4 }}
      ></FontAwesomeIcon>
      {AddressValidation(props) !== "" && (
        <div style={{ maxWidth: 250 }}>
          <span className="badge badge-danger badge-message">{`${validationError}, this is mandatory for creating shipments for auspost, Toll, & TNT`}</span>
        </div>
      )}
    </div>
  );
};
export default AddressContainer;
