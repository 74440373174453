const setUser = (user) => {
  //console.log('setUser SET_USER user',user);
  return {
    type: "SET_USER",
    payLoad: user,
  };
};
const logOut = () => {
  return {
    type: "LOG_OUT",
  };
};

export default {
  setUser,
  logOut,
};
