import React, { Fragment, useState } from "react";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.css";
import "./ManifestGrid.css";
import * as ManifestService from "./ManifestService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import * as OrderSummaryService from "../Home/OrderSummaryService";

const ManifestGrid = (props) => {
  console.log("entered  ManifestGrid");
  const [isLoading] = useState(false);
  const [spinner, setSpinner] = useState(props.spinner);
  console.log("ManifestGrid props.spinner", props.spinner);

  const DownLoadManifest = (manifestId, location, courierFromStore) => {
    OrderSummaryService.DownLoadPdf(manifestId, location, courierFromStore).then((response) => {
      if (response.status === 200) {
        console.log("downLoadHandler 200 response", response);
        console.log("downLoadHandler 200 response.data", response.data);
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.click();
      }
    });
  };

  const viewDetailsHandler = (manifestId) => {
    setSpinner(true);
    console.log("viewDetailsHandler manifestId", manifestId);
    console.log("ManifestTab ViewDetailsCallback manifestId ", manifestId);
    ManifestService.GetShipmentsByManifestId(manifestId).then((response) => {
      if (response.status === 200) {
        setSpinner(false);
        props.CallBack(response.data);

        console.log("viewDetailsHandler  GetShipmentsByManifestId success", JSON.stringify(response));
      } else {
        console.log("viewDetailsHandler  GetShipmentsByManifestId else", JSON.stringify(response));
      }
    });
  };
  const renderTableHeader = () => {
    return (
      <Fragment>
        <th>#</th>
        <th>Created Date</th>
        <th>Created By</th>
        <th>Manifest #</th>
        <th>Courier</th>
        <th>Total Cost</th>
        <th>View</th>
        <th>Download</th>
      </Fragment>
    );
  };

  const renderTableData = () => {
    return props.manifestArr.map((manifestItem, index) => {
      const { createdDate, createdBy, manifestid, totalCost, courier, location } = manifestItem; //destructuring
      return (
        <tr key={manifestid}>
          <td>{index + 1}</td>
          <td>{createdDate}</td>
          <td>{createdBy}</td>
          <td>{manifestid}</td>
          <td>{courier}</td>
          <td>{totalCost}</td>
          <td>
            <button
              type="button"
              className="btn btn-outline-dark btn-sm viewButtonFont"
              onClick={() => {
                viewDetailsHandler(manifestid, location);
              }}
            >
              {spinner ? (
                <Fragment>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Fetching....
                </Fragment>
              ) : (
                "View Shipments"
              )}
            </button>
          </td>
          <td>
            <FontAwesomeIcon
              icon={faFilePdf}
              color="red"
              title="Open PDF"
              id="ManifestPdf"
              size="2x"
              onClick={() => {
                DownLoadManifest(manifestid, location, courier);
              }}
            ></FontAwesomeIcon>
          </td>
        </tr>
      );
    });
  };

  return (
    <div>
      {!isLoading ? (
        <div className="table-responsive gridHolderManifestGrid">
          <table id="ManifestGrid" className="table table-striped ManifestGrid">
            <thead>
              <tr>{renderTableHeader()}</tr>
            </thead>
            <tbody>{renderTableData()}</tbody>
          </table>
        </div>
      ) : (
        <Fragment></Fragment>
      )}
    </div>
  );
};
export default ManifestGrid;
