import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.min.css";

import store from "./redux/store";
import Main from "./Main";

const root = createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <Main />
  </Provider>,
);
