import React, { Fragment, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.css";
import "./ManifestShipmentGrid.css";

const ManifestShipmentGrid = (props) => {
  console.log("props.shipmentSummary", props.shipmentSummary);

  console.log("entered  ManifestGrid");
  const [isLoading] = useState(false);

  const renderTableHeader = () => {
    return (
      <Fragment>
        {/* <th><Checkbox  ></Checkbox></th> */}
        <th>#</th>
        <th>Manifest#</th>
        <th>Customer Account</th>
        <th>Invoice Number</th>
        <th>SalesShipment#</th>
        <th>Customer Name</th>
        <th>Total Cost</th>
        <th>Postage Code</th>
        <th>Label</th>
      </Fragment>
    );
  };

  const renderTableData = () => {
    return props.shipmentSummary.map((shipment, index) => {
      const {
        customerAccount,
        postageCode,
        manifestId,
        totalCost,
        salesShipmentNum,
        invoiceNumber,
        customerName,
        labelUrl,
      } = shipment; //destructuring
      console.log("renderTableData props.shipmentSummary", props.salesShipmentNum);
      return (
        <tr key={salesShipmentNum}>
          {/*  <td scope="col"><Checkbox  onCheckBoxChange={()=>CheckBoxChangeHandler(manifestid)}  ></Checkbox></td> */}
          <td>{index + 1}</td>
          <td>{manifestId}</td>
          <td>{customerAccount}</td>
          <td>{invoiceNumber}</td>
          <td>{salesShipmentNum}</td>
          <td>{customerName}</td>
          <td>{totalCost}</td>
          <td>{postageCode}</td>
          <td>
            <a href={labelUrl} target="_blank" rel="noreferrer">
              {" "}
              <FontAwesomeIcon icon={faFilePdf} color="red"></FontAwesomeIcon>{" "}
            </a>
          </td>
        </tr>
      );
    });
  };

  return (
    <div>
      {!isLoading ? (
        <div className="table-responsive gridHolderSummary">
          <table id="ManifestShipmentGrid" className="table table-striped ManifestShipmentGrid">
            <thead>
              <tr>{renderTableHeader()}</tr>
            </thead>
            <tbody>{renderTableData()}</tbody>
          </table>
        </div>
      ) : (
        <Fragment></Fragment>
      )}
    </div>
  );
};
export default ManifestShipmentGrid;
