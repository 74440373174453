import * as shippingConst from "../AppConstant/ShippingConstants";
import * as listHelper from "../Helper/ListHelper";

const filterByLocation = (location) => (shipment) =>
  location === shippingConst.FROM_ADDRESS["All Locations"].code ||
  shipment.fromStreet === shippingConst.FROM_ADDRESS[location].fromStreet;

const filterByCourier = (courier) => (shipment) => shipment.courierName === courier;

const filterByText = (text) => (shipment) => {
  const searchText = text?.toLowerCase();
  return (
    !searchText ||
    shipment.invoiceNumber?.toLowerCase().includes(searchText) ||
    shipment.salesShipmentNum?.toLowerCase().includes(searchText) ||
    shipment.customerName?.toLowerCase().includes(searchText) ||
    shipment.customerNameAlias?.toLowerCase().includes(searchText) ||
    shipment.customerAccount?.toLowerCase().includes(searchText)
  );
};

export const filterShipments = (allShipments, courier, location, searchText = "") => {
  try {
    console.log("FilterShipments  location, courier, text", location, courier, searchText);
    return allShipments
      .filter(filterByLocation(location))
      .filter(filterByCourier(courier))
      .filter(filterByText(searchText));
  } catch (exception) {
    return exception;
  }
};

export const GetCountByCourier = (allShipments) => {
  try {
    const auspostCount = listHelper.GetCount(allShipments, shippingConst.COURIER.AUSPOST.name);
    const pickUpCount = listHelper.GetCount(allShipments, shippingConst.COURIER.PICKUP.name);
    const ronwCount = listHelper.GetCount(allShipments, shippingConst.COURIER.RONW.name);
    const starTrackCount = listHelper.GetCount(allShipments, shippingConst.COURIER.STARTRACK.name);
    return {
      auspost: auspostCount,
      pickUp: pickUpCount,
      ronw: ronwCount,
      starTrack: starTrackCount,
    };
  } catch (exception) {
    console.log("exception in shipmentFilter GetCountByCourier");
    return exception;
  }
};

//This is for setting the isSelected flag to true once the check box is seleted
export const SetSelectedFlag = (shipments, shipmentNum) => {
  try {
    const tempShipments = [...shipments];
    const objToUpdate = tempShipments.find((obj) => {
      return obj.salesShipmentNum === shipmentNum;
    });
    objToUpdate.isSelected = !objToUpdate.isSelected;
    return tempShipments;
  } catch (exception) {
    console.log("exception in shipmentFilter SetSelectedFlag");
    return exception;
  }
};

//checkBox all
//This is for toggling  the isSelected flag for all to true or false  once the check box all from header is seleted
export const ToggleSelectAll = (shipments, flag) => {
  try {
    const tempShipments = [...shipments];
    tempShipments.forEach((element) => {
      element.isSelected = flag;
    });
    return tempShipments;
  } catch (exception) {
    console.log("exception in shipmentFilter ToggleSelectAll");
    return exception;
  }
};

export const IsManifestButton = (selectedShipments) => {
  try {
    const isTrackingAvailable = selectedShipments.find((shipment) => shipment.trackingNumber !== null);
    return typeof isTrackingAvailable !== "undefined";
  } catch (exception) {
    console.log("exception in shipmentFilter IsManifestButton");
    return exception;
  }
};
