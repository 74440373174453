import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";

const PostageUpdateModal = (props) => {
  console.log("PostageUpdateModal props.postageName", props.postageName);
  return (
    <Modal centered show={props.show} onHide={props.closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          {props.message} {props.postageName}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="d-flex justify-content-center">
          <FontAwesomeIcon icon={faCheckCircle} color="black" size="3x">
            {" "}
          </FontAwesomeIcon>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="text-left">
                <button
                  className="btn btn-dark btn-sm"
                  onClick={() =>
                    props.PostageUpdateModalCallBack({
                      action: false,
                      postage: props.postageName,
                    })
                  }
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className="col">
              <div>
                <button
                  className="btn btn-secondary btn-sm float-right"
                  onClick={() =>
                    props.PostageUpdateModalCallBack({
                      action: true,
                      postage: props.postageName,
                    })
                  }
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
export default PostageUpdateModal;
