import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "./DeleteShipmentModal.css";

const DeleteShipmentModal = (props) => {
  const [deleteComment, setTextArea] = useState("");
  const validateDeleteText = () => {
    if (deleteComment === "") {
      alert("Please provide delete reason");
      return false;
    } else {
      props.onClick({
        action: "delete",
        shipmentNo: props.shipmentNo,
        deleteReason: deleteComment,
      });
    }
  };
  return (
    <Modal show={props.show} onHide={() => props.onHide({ msg: "onHide event triggered" })} centered>
      <Modal.Header closeButton>
        <Modal.Title> Are you sure to delete this shipment {props.shipmentNo}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label htmlFor="comment">Comment:</label>
          <textarea
            id="deleteComment"
            value={deleteComment}
            onChange={(e) => {
              setTextArea(e.target.value);
            }}
            className="form-control"
            rows="3"
          ></textarea>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="text-left">
                <button className="btn btn-dark btn-sm" onClick={() => props.onClick({ action: "cancel" })}>
                  Cancel
                </button>
              </div>
            </div>
            <div className="col">
              <div className="text-right">
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() => {
                    validateDeleteText();
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
export default DeleteShipmentModal;
