import axios from "axios";
import * as UrlConfig from "../AppConstant/UrlConfig";

export const UpdateLocation = async (salesShipments) => {
  const authToken = sessionStorage.getItem("AuthToken");

  console.log("inside the UpdateLocation salesShipments", salesShipments);

  try {
    return await axios.post(UrlConfig.UPDATE_LOCATION_URL, JSON.stringify(salesShipments), {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });
  } catch (exception) {
    console.log("LocationService UpdateLocation exception", exception);
    return exception;
  }
};

//tempOrders : copy of shipmentOrder state obj
//new address details grabed from the dropdown
export const GetUpdatedOrder = (selectedShipments, newAddress, salesShipmentNum) => {
  const tempOrders = [...selectedShipments];
  const toUpdateObj = tempOrders.find((obj) => {
    return obj.salesShipmentNum === salesShipmentNum;
  });
  console.log("GetUpdatedOrder  toUpdateObj ", toUpdateObj);
  toUpdateObj.fromCity = newAddress.fromCity;
  toUpdateObj.fromCountry = newAddress.fromCountry;
  toUpdateObj.fromPostcode = newAddress.fromPostcode;
  toUpdateObj.fromState = newAddress.fromState;
  toUpdateObj.fromStreet = newAddress.fromStreet;

  return tempOrders;
};

//Select the saleshipment order object to update by salesShipmentnumberpassed
//Then convert that in to a array object
export const GetUpdateArray = (tempOrders, selectedShipmentNum) => {
  const toUpdateObj = tempOrders.find((obj) => {
    return obj.salesShipmentNum === selectedShipmentNum;
  });
  return new Array(toUpdateObj);
};
