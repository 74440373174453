import React from "react";
import { COURIER } from "../AppConstant/ShippingConstants";
import * as shipmentFilter from "./shipmentFilter";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../redux/actions";

const CourierTabBar = (props) => {
  const dispatch = useDispatch();
  const allShipmentFromStore = useSelector((state) => state.shipment.allShipments);
  const locationFromStore = useSelector((state) => state.shipment.location);
  const courierFromStore = useSelector((state) => state.shipment.courier);
  const courierCount = useSelector((state) => state.shipment.count);
  const searchTextFromStore = useSelector((state) => state.shipment.searchText);
  useSelector((state) => state.shipment.selected);
  const activeTabCss = "nav-item nav-link active";
  const tabCss = "nav-item nav-link";

  // currently courier props is passed for fitlering recrods,
  // because courierFromStore is having the previous value  TODO:Nevin:Need to check
  const handleTabClick = (courier) => {
    dispatch(allActions.shipmentActions.setCourier(courier));
    console.log("handleTabClick tab selected ", courier);
    console.log(
      "handleTabClick tab selected, courierFromStore, locationFromStore, text  ",
      courier,
      courierFromStore,
      locationFromStore,
      searchTextFromStore,
    );
    const selectedShipments = shipmentFilter.filterShipments(
      allShipmentFromStore,
      courier,
      locationFromStore,
      searchTextFromStore,
    );
    console.log("handleTabClick selectedShipments ", selectedShipments);
    dispatch(allActions.shipmentActions.setSelected(selectedShipments));
    props.toggleManifestCallBack(selectedShipments, "CourierTabBar");
  };

  return (
    <nav>
      <div className="nav nav-tabs" id="courierTab" role="tablist">
        <button
          className={courierFromStore === COURIER.AUSPOST.name ? activeTabCss : tabCss}
          id="nav-auspost-tab"
          data-toggle="tab"
          role="tab"
          aria-selected="true"
          onClick={() => handleTabClick(COURIER.AUSPOST.name)}
        >
          Auspost &nbsp;
          <span className="badge badge-pill badge-dark">{courierCount && courierCount.auspost}</span>
        </button>
        <button
          className={courierFromStore === COURIER.PICKUP.name ? activeTabCss : tabCss}
          id="nav-pickup-tab"
          data-toggle="tab"
          role="tab"
          aria-selected="false"
          onClick={() => handleTabClick(COURIER.PICKUP.name)}
        >
          Pickup &nbsp;
          <span className="badge badge-pill badge-dark">{courierCount && courierCount.pickUp}</span>{" "}
        </button>
        <button
          className={courierFromStore === COURIER.STARTRACK.name ? activeTabCss : tabCss}
          id="nav-pickup-tab"
          data-toggle="tab"
          role="tab"
          aria-selected="false"
          onClick={() => handleTabClick(COURIER.STARTRACK.name)}
        >
          StarTrack &nbsp;
          <span className="badge badge-pill badge-dark">{courierCount && courierCount.starTrack}</span>{" "}
        </button>
      </div>
    </nav>
  );
};

export default CourierTabBar;
