import React, { Fragment, useEffect, useState } from "react";
import ManifestGrid from "./ManifestGrid";
import ManifestShipmentGrid from "./ManifestShipmentGrid";
import * as ManifestService from "./ManifestService";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import AlertModal from "../CustomControl/AlertModal";
import { CLICK_ACTION } from "../AppConstant/ShippingConstants";
import ErrorModal from "../CustomControl/ErrorModal";
import ProcessingModal from "../CustomControl/ProcessingModal";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../redux/actions";
import * as sessionService from "../Session/StorageService";
import * as TextConfig from "../AppConstant/TextConfig";

const ManifestTab = () => {
  const dispatch = useDispatch();
  console.log("entered  ManifestTab");
  const [filterSpinner, setFilterSpinner] = useState(false);

  useSelector((state) => state.shipment.courier);

  const [startDate, setStartDate] = useState(new Date().toLocaleString("en-US", { timeZone: "Australia/Sydney" }));
  const [endDate, setEndDate] = useState(new Date().toLocaleString("en-US", { timeZone: "Australia/Sydney" }));
  const [datePickerStartDate, setDatePickerStartDate] = useState(new Date());
  const [datePickerEndDate, setDatePickerEndDate] = useState(new Date());
  const [showManifestShipmentGrid, setManifestShipmentGrid] = useState(false);
  const [showProcessingModal] = useState(false);
  const [alertModalMessage, setAlertModalMessage] = useState("");
  const [showAlertModal, setShowAlertModal] = useState(false);

  const [showError, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [, setChartData] = useState([]);

  console.log("Manifesttab new Date()", new Date());
  console.log("Manifesttab startDate", startDate);
  const [manifestList, setManifestList] = useState([]);

  const [shipmentSummary, setShipmentSummary] = useState([]);
  useEffect(() => {
    console.log("useEffect shipmentSummary entry");
    if (shipmentSummary.length > 0) {
      console.log("useEffect shipmentSummary length", shipmentSummary.length);
      setManifestShipmentGrid(true);
    }
  }, [shipmentSummary]);

  useEffect(() => {
    const manifestParam = {
      startDate: format(datePickerStartDate, "yyyy-MM-dd"),
      endDate: format(datePickerEndDate, "yyyy-MM-dd"),
      createdBy: "",
    };

    console.log("useEffect manifestParam ", JSON.stringify(manifestParam));
    ManifestService.GetManifestSummary(manifestParam).then((response) => {
      console.log("useEffect GetManifestSummary response", JSON.stringify(response));
      if (!Object.prototype.hasOwnProperty.call(response, TextConfig.STATUS)) {
        setErrorMessage(TextConfig.ERR_CONNECTION_REFUSED);
        setErrorModal(true);
        return;
      }
      if (response.status === 200) {
        console.log("useEffect GetManifestSummary success", JSON.stringify(response.data));
        setManifestList(response.data);
      } else if (response.status === 401) {
        console.log("useEffect GetManifestSummary 401", response);
        dispatch(allActions.userActions.logOut());
        sessionService.SetSessionExpiry();
      } else if (response.response.status === 401) {
        console.log("useEffect GetManifestSummary 401", response.response);
        dispatch(allActions.userActions.logOut());
        sessionService.SetSessionExpiry();
      } else {
        console.log("useEffect GetManifestSummary else", JSON.stringify(response));
      }
    });
  }, [datePickerEndDate, datePickerStartDate, dispatch]);

  const closeCancelModal = (props) => {
    console.log("inside CLICK_ACTION.ERRORMODALCLOSE", props);
    if (props.action === CLICK_ACTION.CLOSE && props.source === CLICK_ACTION.ERRORMODALCLOSE) {
      setErrorMessage("");
      setErrorModal(false);
    }
  };

  const AltertModalCallBack = () => {
    setShowAlertModal(false);
    setErrorModal(false);
  };

  const ViewDetailsCallBack = (orders) => {
    setManifestShipmentGrid(false);
    setShipmentSummary(orders);
    setChartData(GetChartData(orders));
  };

  const GetChartData = (orders) => {
    const AusEXPost = orders.filter((order) => order.postageCode === "AusEXPost").length;
    const Auspost = orders.filter((order) => order.postageCode === "AusPost").length;
    const chartdata = [];
    if (AusEXPost > 0) {
      chartdata.push({
        x: "AuspostExpress",
        y: AusEXPost,
        label: "AuspostExpress : " + AusEXPost,
      });
    }
    if (Auspost > 0) {
      chartdata.push({
        x: "AusParcelPost",
        y: Auspost,
        label: "Auspost : " + Auspost,
      });
    }
    console.log("GetChartData  chartdata", chartdata);
    return chartdata;
  };
  const filterClickHandler = () => {
    setStartDate(datePickerStartDate);
    setEndDate(datePickerEndDate);
    setFilterSpinner(true);
    console.log("filterClickHandler datePickerStartDate", datePickerStartDate);
    console.log("filterClickHandler datePickerEndDate" + datePickerEndDate);
    console.log("filterClickHandler startDate" + startDate);
    console.log("filterClickHandler endDate" + endDate);
    const manifestParam = {
      startDate: format(datePickerStartDate, "yyyy-MM-dd"),
      endDate: format(datePickerEndDate, "yyyy-MM-dd"),
      createdBy: "",
    };
    ManifestService.GetManifestSummary(manifestParam).then((response) => {
      console.log("GetManifestSummary response", response);
      console.log("GetManifestSummary response.status", response.status);
      if (response.status === 200) {
        setManifestList(response.data);
        setFilterSpinner(false);
        setManifestShipmentGrid(false);
        if (response.data.length === 0) {
          setAlertModalMessage("No manifest for the given date");
          setShowAlertModal(true);
        }
      } else if (response.status === 401) {
        dispatch(allActions.userActions.logOut());
        sessionService.SetSessionExpiry();
      } else if (response.response.status === 401) {
        dispatch(allActions.userActions.logOut());
        sessionService.SetSessionExpiry();
      } else {
        setManifestShipmentGrid(false);
        console.log("filterClickHandler GetManifestSummary else", JSON.stringify(response));
      }
    });
  };

  return (
    <div className="container-fluid">
      <div className="container-fluid pt-3 pb-3">
        <div className="row">
          <div className="col-sm"></div>
          <div className="col-sm"></div>
          <div className="col-sm">
            {" "}
            <div className="divRightAlign">Start Date :</div>
          </div>
          <div className="col-sm">
            <DatePicker
              selected={datePickerStartDate}
              filterDate={(maxDate) => {
                return new Date() > maxDate;
              }}
              dateFormat="dd/MM/yyyy"
              onChange={(date) => setDatePickerStartDate(date)}
            >
              {" "}
            </DatePicker>
          </div>
          <div className="col-sm">
            {" "}
            <div className="divRightAlign">End Date :</div>
          </div>
          <div className="col-sm">
            {" "}
            <DatePicker
              selected={datePickerEndDate}
              filterDate={(maxDate) => {
                return new Date() > maxDate;
              }}
              dateFormat="dd/MM/yyyy"
              onChange={(date) => setDatePickerEndDate(date)}
            >
              {" "}
            </DatePicker>
          </div>
          <div className="col-sm">
            <button type="button" onClick={filterClickHandler} className="btn btn-dark btn-sm">
              {filterSpinner ? (
                <Fragment>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Fetching....
                </Fragment>
              ) : (
                "Refresh"
              )}
            </button>
          </div>
          <div className="col-sm"></div>
          <div className="col-sm"></div>
        </div>
        <div className="row">
          <div className="col-sm">
            {showProcessingModal && (
              <ProcessingModal show={showProcessingModal} title="Create Manifest in progress"></ProcessingModal>
            )}
            <AlertModal show={showAlertModal} message={alertModalMessage} closeModal={AltertModalCallBack}></AlertModal>
            {showError && (
              <ErrorModal show={showError} message={errorMessage} closeModal={closeCancelModal}></ErrorModal>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm">
          <ManifestGrid manifestArr={manifestList} spinner={false} CallBack={ViewDetailsCallBack}></ManifestGrid>
        </div>
      </div>
      {showManifestShipmentGrid && (
        <div className="row">
          <div className="col-sm">
            <ManifestShipmentGrid shipmentSummary={shipmentSummary}></ManifestShipmentGrid>
          </div>
        </div>
      )}
    </div>
  );
};
export default ManifestTab;
