import * as config from "../redux/reduxConstants";

//This will handle if there is a unauthorized 401 error in http requests
export const SetSessionExpiry = () => {
  sessionStorage.setItem("sessionExpired", true);
  sessionStorage.removeItem("persistantUser");
  sessionStorage.removeItem("AuthToken");
  sessionStorage.removeItem("user");
};

export const LogOut = () => {
  sessionStorage.removeItem("persistantUser");
  sessionStorage.removeItem("user");
  sessionStorage.removeItem("tokens");
  sessionStorage.removeItem("AuthToken");
  sessionStorage.removeItem("Orders");
  sessionStorage.removeItem(config.REDUX_USER_STATE);
};
