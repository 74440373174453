import React from "react";
import { NavLink } from "react-router-dom";
import "../CommonStyles/Common.css";

const SecondNavbar = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm">
          <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
            <div className="collapse navbar-collapse" id="navbarText">
              <ul className="navbar-nav mr-auto" id="secondNav">
                <li>
                  <NavLink to="/" className="nav-link">
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/ManifestTab" className="nav-link">
                    Manifest
                  </NavLink>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};
export default SecondNavbar;
