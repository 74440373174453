import * as shippingConst from "../AppConstant/ShippingConstants";

export const UpdatePostageObject = (shipmentOrders, postageName) => {
  console.log("UpdatePostageObject COURIER_SERVICES", shippingConst.COURIER_SERVICES);
  console.log("UpdatePostageObject postageName", postageName);
  const shipmentNoList = shipmentOrders.map((order) => order.salesShipmentNum);
  const postage = shippingConst.COURIER_SERVICES.find((courier) => courier.displayName === postageName);

  return {
    Shipments: shipmentOrders,
    ShipmentNumbers: shipmentNoList,
    PostageName: postage.postageName,
    PostageType: postage.PostageType,
    postageCode: postage.postageCode,
  };
};
