import React, { useState, useEffect, useCallback } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "@fortawesome/fontawesome-svg-core";
import "@fortawesome/react-fontawesome";
import "./ShipmentPage.css";
import ShipmentActionBar from "./ShipmentActionBar";
import ShipmentGrid from "./ShipmentGrid";
import * as shippingConst from "../AppConstant/ShippingConstants";
import ProcessingModal from "../CustomControl/ProcessingModal";
import AlertModal from "../CustomControl/AlertModal";
import ErrorModal from "../CustomControl/ErrorModal";
import * as shipmentService from "./shipmentService";
import * as shipmentFilter from "./shipmentFilter";

import { useSelector, useDispatch } from "react-redux";
import allActions from "../redux/actions";
import CourierTabBar from "./CourierTabBar";

import { CLICK_ACTION } from "../AppConstant/ShippingConstants";
import * as TextConfig from "../AppConstant/TextConfig";

import * as sessionService from "../Session/StorageService";

const ShipmentPage = () => {
  const dispatch = useDispatch();
  const allShipmentFromStore = useSelector((state) => state.shipment.allShipments);
  const locationFromStore = useSelector((state) => state.shipment.location);
  const courierFromStore = useSelector((state) => state.shipment.courier);
  const searchTextFromStore = useSelector((state) => state.shipment.searchText);
  const [showSOGrid, setShowSOGrid] = useState(false);

  const [clickedCount, doSubmit] = useState(0); //GMZK

  const [showProcessingModal, setProcessingModal] = useState(false);
  const [processingModalMsg, setProcessingModalMsg] = useState("");

  const [showAlertModal] = useState(false);
  const [alertModalMessage, setAlertModalMessage] = useState("");
  const [showError, setError] = useState(false);
  const [trigger, setTrigger] = useState("");

  const closeCancelModal = (props) => {
    console.log("inside CLICK_ACTION.ERRORMODALCLOSE", props);

    if (
      props.action === CLICK_ACTION.CLOSE &&
      props.source === CLICK_ACTION.ERRORMODALCLOSE &&
      props.trigger === TextConfig.SESSION_EXPIRY
    ) {
      setTrigger("");
      setAlertModalMessage("");
      setError(false);
      sessionStorage.removeItem("persistantUser");
      dispatch(allActions.userActions.logOut());
    }
    if (props.action === CLICK_ACTION.CLOSE && props.source === CLICK_ACTION.ERRORMODALCLOSE) {
      setAlertModalMessage("");
      setError(false);
    }
  };

  const toggleManifest = useCallback(
    (selectedShipments) => {
      const showManifestButton = shipmentFilter.IsManifestButton(selectedShipments);
      dispatch(allActions.shipmentActions.setManifestButton(showManifestButton));
    },
    [dispatch],
  );

  const fetchShipments = useCallback(
    (props) => {
      setProcessingModalMsg("Fetching Shipments");
      setProcessingModal(true);
      shipmentService.GetShipments(props.axRefresh, props.startDate, props.endDate).then((response) => {
        setProcessingModal(false);

        if (!Object.prototype.hasOwnProperty.call(response, TextConfig.STATUS)) {
          setAlertModalMessage(TextConfig.ERR_CONNECTION_REFUSED);
          setError(true);
          return;
        }

        if (response.status === 200) {
          dispatch(allActions.shipmentActions.setAll(response.data));
          const countByCourier = shipmentFilter.GetCountByCourier(response.data);
          dispatch(allActions.shipmentActions.setCount(countByCourier));
          setShowSOGrid(true);
        } else if (response.status === 500) {
          console.log("fetchShipments response.status 500 response", response);
          setAlertModalMessage(TextConfig.INT_SERVER_ERROR);
          setError(true);
        }
        if (response.status === 401) {
          dispatch(allActions.userActions.logOut());
          sessionService.SetSessionExpiry();
        }
      });
    },
    [dispatch],
  );

  useEffect(() => {
    const selectedShipments = shipmentFilter.filterShipments(
      allShipmentFromStore,
      courierFromStore,
      locationFromStore,
      searchTextFromStore,
    );
    toggleManifest(selectedShipments);
    dispatch(allActions.shipmentActions.setSelected(selectedShipments));
  }, [allShipmentFromStore, courierFromStore, dispatch, locationFromStore, searchTextFromStore, toggleManifest]);

  useEffect(() => {
    console.log("ShipmentPage useEffect initializeLocation entry", locationFromStore);
    if (locationFromStore === "" || locationFromStore === undefined) {
      console.log("ShipmentPage useEffect initializeLocation", locationFromStore);
      dispatch(allActions.shipmentActions.setLocation(shippingConst.FROM_ADDRESS["All Locations"].code));
    }
    if (courierFromStore === "" || courierFromStore === undefined) {
      console.log("ShipmentPage useEffect initializeLocation", courierFromStore);
      dispatch(allActions.shipmentActions.setCourier(shippingConst.COURIER_SERVICES_SELECTED));
    }
  }, [courierFromStore, dispatch, locationFromStore]);

  useEffect(() => {
    const startDate = new Date();
    fetchShipments({
      axRefresh: false,
      startDate: startDate,
      endDate: startDate,
    });
  }, [fetchShipments]);

  const refreshButtonCallBack = (props) => {
    console.log("shipmentPage  refreshButtonCallBack props", props);
    fetchShipments(props);
  };

  return (
    <div className="container-fluid">
      <div className="container-fluid">
        <div className="row" style={{ height: "2em" }}>
          {/*  this is to add vertical space  */}
        </div>
        <div className="row">
          <div className="col-sm">
            {showSOGrid && <CourierTabBar toggleManifestCallBack={toggleManifest}></CourierTabBar>}
          </div>
        </div>
      </div>
      <div className="container-fluid pt-2 pb-2">
        <ShipmentActionBar
          toggleManifestCallBack={toggleManifest}
          callBack={refreshButtonCallBack}
          clickSubmit={clickedCount}
        ></ShipmentActionBar>
        <div className="row">
          <div className="col-sm">
            {showProcessingModal && (
              <ProcessingModal show={showProcessingModal} title={processingModalMsg}></ProcessingModal>
            )}
            <AlertModal show={showAlertModal} message={alertModalMessage} closeModal={() => null}></AlertModal>
            {showError && (
              <ErrorModal
                show={showError}
                trigger={trigger}
                message={alertModalMessage}
                closeModal={closeCancelModal}
              ></ErrorModal>
            )}
          </div>
        </div>
      </div>

      <div>{showSOGrid && <ShipmentGrid callBack={refreshButtonCallBack}></ShipmentGrid>}</div>
      <div className="container-fluid pt-3 pb-3">
        <div className="row">
          <div className="col-sm"> </div>
          <div className="col-sm"> </div>
          <div className="col-sm"></div>
          <div className="col-sm"> </div>
          <div className="col-sm"> </div>
          <div className="col-sm"> </div>
          <div className="col-sm"></div>
          <div className="col-sm"> </div>
        </div>
      </div>
      <div className="col-sm">
        <button type="button" onClick={() => doSubmit((prev) => prev + 1)} className="btn btn-dark btn-sm float-right">
          Manual Tracking Number
        </button>
      </div>
    </div> //GMZK
  );
};
export default ShipmentPage;
