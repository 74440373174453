import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faUser } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../redux/actions";

import * as sessionService from "../Session/StorageService";
import { PalloysLogoSquare } from "../components/common/svg/palloys/PalloysLogoSquare";

const HomeTopNavBar = () => {
  const userFromStore = useSelector((state) => state.userReducer.user);
  const dispatch = useDispatch();
  const [currentuser, sessionUserHandler] = useState(JSON.parse(sessionStorage.getItem("user")) || "");
  const LogOut = () => {
    dispatch(allActions.userActions.logOut());
    dispatch(allActions.shipmentActions.resetAll());
    sessionService.LogOut();
  };

  useEffect(() => {
    sessionUserHandler(currentuser);
  }, [currentuser]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm">
          <nav className="navbar navbar-expand-sm navbar-dark" style={{ backgroundColor: "#2862a4" }}>
            <a className="navbar-brand" href="/">
              <div style={{ width: 32, height: 32, display: "inline-block" }}>
                <PalloysLogoSquare />
              </div>
              <span style={{ fontWeight: 500, fontSize: 18, marginLeft: 8, letterSpacing: 0.5 }}>Palloys Shipping</span>
            </a>
            <div className="navbar-collapse collapse w-100 order-3 dual-collapse2">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <div className="nav-link">
                    {" "}
                    <FontAwesomeIcon
                      icon={faUser}
                      title="User"
                      color="white"
                      id="userIcon"
                      size="1x"
                    ></FontAwesomeIcon>{" "}
                    &nbsp;
                    {userFromStore && userFromStore.firstName}
                  </div>
                </li>
                <li className="nav-item">
                  <div className="nav-link">
                    <FontAwesomeIcon
                      icon={faSignOutAlt}
                      color="white"
                      title="Logout"
                      id="signOut"
                      size="1x"
                      onClick={LogOut}
                    ></FontAwesomeIcon>
                    &nbsp;&nbsp;Logout
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default HomeTopNavBar;
