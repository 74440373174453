import axios from "axios";
import * as UrlConfig from "../AppConstant/UrlConfig";

export const Authenticate = async (userName, password) => {
  try {
    return await axios.post(UrlConfig.AUTH_URL, {
      userName,
      password,
    });
  } catch (exception) {
    if (!exception.response) {
      return exception;
    }
    return exception.response;
  }
};
