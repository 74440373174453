import React from "react";
import { Modal } from "react-bootstrap";
import "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "./ProcessingModal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const ProcessingModal = (props) => {
  const fontStyle = {
    fontweight: "bolder",
  };
  console.log("ProcessingModal show", props.show);
  return (
    <Modal show={props.show} centered>
      <Modal.Header className="d-flex justify-content-center">
        <Modal.Title>
          {" "}
          <div style={fontStyle}> {props.title}</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-center">
          <FontAwesomeIcon icon={faSpinner} className="fa-pulse" color="black" size="4x">
            {" "}
          </FontAwesomeIcon>
        </div>
      </Modal.Body>

      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default ProcessingModal;
