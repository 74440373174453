import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import * as shippingConst from "../AppConstant/ShippingConstants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRandom } from "@fortawesome/free-solid-svg-icons";

const SwitchCourierDropdown = (props) => {
  const handleSelect = (e) => {
    console.log("Switch Courier selected ", e);
    props.shipmentActionCallback(e);
  };

  const GetCourierList = () => {
    return (
      <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
        {shippingConst.COURIER_SERVICES.map((courier) => (
          <button
            type="button"
            className="dropdown-item"
            key={courier.displayName}
            onClick={() => handleSelect(`${courier.displayName}`)}
          >
            {courier.displayName}
          </button>
        ))}
      </div>
    );
  };

  return (
    <div className="dropdown" id="drpCourier">
      <button
        type="button"
        className="btn btn-dark dropdown-toggle"
        id="dropdownMenuLink"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <FontAwesomeIcon icon={faRandom} className="mr-2"></FontAwesomeIcon>
        Switch Courier
      </button>
      {GetCourierList()}
    </div>
  );
};
export default SwitchCourierDropdown;
