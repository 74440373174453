import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import React, { useEffect } from "react";

export const AuthenticationRequired = ({ children }) => {
  const isLoggedIn = useSelector((state) => state.userReducer.loggedIn);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (!isLoggedIn) {
      navigate({ pathname: "/Login", state: { referer: location } });
    }
  }, [isLoggedIn, location, navigate]);
  return isLoggedIn ? <>{children}</> : null;
};
