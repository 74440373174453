import React, { useCallback, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "@fortawesome/fontawesome-svg-core";
import "@fortawesome/react-fontawesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as shippingConst from "../AppConstant/ShippingConstants";
import { CLICK_ACTION } from "../AppConstant/ShippingConstants";
import LocationFilter from "../Home/LocationFilter";
import CreateManifestModal from "../Home/CreateManifestModal";
import ManifestResponseModal from "../Home/ManifestResponseModal";
import PostageUpdateModal from "../Home/PostageUpdateModal";
import CommonProgressModal from "../CustomControl/CommonProgressModal";
import SubmitPickUpModal from "../Home/SubmitPickUpModal";
import * as ListHelper from "../Helper/ListHelper";
import * as PostageHelper from "../Helper/PostageHelper";

import * as ManifestHelper from "../Helper/ManifestHelper";
import * as validationHelper from "../Helper/validationHelper";
import * as shipmentService from "./shipmentService";
import * as PickUpService from "../Home/PickUpService";
import * as PostageService from "../Home/PostageService";
import * as TextConfig from "../AppConstant/TextConfig";

import { useDispatch, useSelector } from "react-redux";
import allActions from "../redux/actions";
import * as shipmentFilter from "./shipmentFilter";
import SwitchCourierDropdown from "./SwitchCourierDropdown";
import ProcessingModal from "../CustomControl/ProcessingModal";
import AlertModal from "../CustomControl/AlertModal";
import ErrorModal from "../CustomControl/ErrorModal";
import * as sessionService from "../Session/StorageService";
import "react-datepicker/dist/react-datepicker.css";
import { faClipboard, faExchangeAlt, faSync, faTimes } from "@fortawesome/free-solid-svg-icons";
import { RefreshModal } from "./RefreshModal";

const ShipmentActionBar = (rootprops) => {
  const [showSubmitPickUpModal, setSubmitPickUpModal] = useState(false);
  const [submitPickUpData, setSubmitPickUpData] = useState([]);

  const dispatch = useDispatch();
  const userFromStore = useSelector((state) => state.userReducer);
  const locationFromStore = useSelector((state) => state.shipment.location);
  const allShipmentFromStore = useSelector((state) => state.shipment.allShipments);
  const courierFromStore = useSelector((state) => state.shipment.courier);
  const selectedShipmentsFromStore = useSelector((state) => state.shipment.selected);
  const showManifestFromStore = useSelector((state) => state.shipment.manifestButton);
  const searchTextFromStore = useSelector((state) => state.shipment.searchText);

  const [showRefreshModal, setShowRefreshModal] = useState(false);

  const [currentuser] = useState(JSON.parse(sessionStorage.getItem("user")));

  const [showManifestModal, setManifestModal] = useState(false);

  const [showManifestReponseModal, setShowManifestResponse] = useState(false);
  const [manifestResponseMessage, setManifestResponseMessage] = useState("");
  const [manifestDetails, setManifestDetails] = useState({});

  const [showPostageUpdateModal, setPostageUpdateModal] = useState(false);
  const [postageUpdateModalMsg, setPostageUpdateModalMsg] = useState("");
  const [postageSelected, setPostageSelected] = useState("");

  const [alertModalMessage, setAlertModalMessage] = useState("");
  const [showAlertModal, setShowAlertModal] = useState(false);

  const [showError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [showCommonProgressModal, setCommonProgressModal] = useState(false);

  const [commonProgressModalTitle, setCommonProgressModalTitle] = useState("");

  const [showProcessingModal, setProcessingModal] = useState(false);
  const [processingModalMsg, setProcessingModalMsg] = useState("");

  const refreshFromDb = useCallback(() => {
    rootprops.callBack({ axRefresh: false });
  }, [rootprops]);

  const closeCancelModal = (props) => {
    console.log("inside CLICK_ACTION.ERRORMODALCLOSE", props);
    if (props.action === CLICK_ACTION.CLOSE && props.source === CLICK_ACTION.ERRORMODALCLOSE) {
      setErrorMessage("");
      setError(false);
    }
  };

  const ClearCheckBoxHeader = useCallback(() => {
    dispatch(allActions.shipmentActions.setShipmentGridChkAll(false));
  }, [dispatch]);

  //Submit Button click
  const submitClickHandler = useCallback(() => {
    if (shippingConst.FROM_ADDRESS[TextConfig.ALL_LOCATIONS].code === locationFromStore || locationFromStore === "") {
      alert(TextConfig.NO_LOCATION_SELECTED);
      return false;
    }
    const selectedOrders = selectedShipmentsFromStore.filter(
      (order) => order.isSelected === true && order.shipmentPriceError === null && order.trackingNumber === null,
    );

    if (selectedOrders.length === 0) {
      alert(TextConfig.NO_ELIGIBLE_ORDERS_FOR_SHIPMENTS);
      ClearCheckBoxHeader();
      return false;
    }

    if (courierFromStore === shippingConst.COURIER_PICKUP) {
      console.log("submit pickup submit");
      setSubmitPickUpData(selectedOrders);
      setSubmitPickUpModal(true);
      return false;
    }

    setProcessingModal(true);
    setProcessingModalMsg("Creating shipment Please wait....");

    shipmentService.CreateShipment(selectedOrders, courierFromStore).then((response) => {
      console.log("submitClickHandler courierFromStore", courierFromStore);

      console.log("submitClickHandler status raw response", response);
      console.log("submitClickHandler status JSON.stringify(response)", JSON.stringify(response));
      if (!Object.prototype.hasOwnProperty.call(response, TextConfig.STATUS)) {
        setProcessingModal(false);
        setAlertModalMessage(TextConfig.ERR_CONNECTION_REFUSED);
        setShowAlertModal(true);
        return;
      }

      if (response.status === 200) {
        setProcessingModal(false);
        setShowAlertModal(true);
        setAlertModalMessage(TextConfig.SHIPMENT_SUCCESS);
        console.log("submitClickHandler status 200");
        refreshFromDb();
        dispatch(allActions.shipmentActions.setShipmentGridChkAll(false));
      } else if (response.status === 500) {
        console.log("submitClickHandler status 500");
        console.log("submitClickHandler status 500, response.data", response.data);
        setProcessingModal(false);
        setErrorMessage(`${response.data}`);
        setError(true);
        refreshFromDb();
        dispatch(allActions.shipmentActions.setShipmentGridChkAll(false));
      } else if (response.status === 204) {
        console.log("submitClickHandler status 204");
        setProcessingModal(false);
        setAlertModalMessage(TextConfig.SHIPMENT_SUCCESS);
        setShowAlertModal(true);
      } else if (response.status === 401) {
        console.log("submitClickHandler response.status 401");
        dispatch(allActions.userActions.logOut());
        sessionService.SetSessionExpiry();
      } else if (response.response.status === 401) {
        console.log("submitClickHandler response.response.status 401");
        dispatch(allActions.userActions.logOut());
        sessionService.SetSessionExpiry();
      } else {
        console.log("submitClickHandler status error", response.message);
        setProcessingModal(false);
      }
    });
  }, [ClearCheckBoxHeader, courierFromStore, dispatch, locationFromStore, refreshFromDb, selectedShipmentsFromStore]);

  useEffect(() => {
    // TODO: This is a very hacky way to initiate the "click" handler from a sibling component. I think this was added
    //       when the button was moved from the toolbar to underneath the table and refactoring the monster submit
    //       method was too much.
    //       Note that useEffect doesn't have dependency on submitClickHandler because this causes it to run too often.
    if (rootprops.clickSubmit !== 0) submitClickHandler();
    // eslint-disable-next-line
  }, [rootprops.clickSubmit]); //GMZK

  useEffect(() => {
    const selectedShipments = shipmentFilter.filterShipments(
      allShipmentFromStore,
      courierFromStore,
      locationFromStore,
      searchTextFromStore,
    );
    dispatch(allActions.shipmentActions.setSelected(selectedShipments));
    rootprops.toggleManifestCallBack(selectedShipments);
  }, [allShipmentFromStore, courierFromStore, dispatch, locationFromStore, rootprops, searchTextFromStore]);

  const handleFilterLocation = (event) => {
    dispatch(allActions.shipmentActions.setLocation(shippingConst.FROM_ADDRESS[event.target.value].code));
  };

  const manifestModalCallBack = (props) => {
    console.log("manifestModalCallBack props", props.status);
    if (props.status === false) {
      setManifestModal(false);
      return false;
    } else {
      setManifestModal(false);
      setProcessingModalMsg("Creating Manifest, Please wait.....");
      setProcessingModal(true);
      //TODO:Nevin:below snippet is used in two places, refactor to avoid duplication
      const shipmentsForManifest = selectedShipmentsFromStore.filter(
        (order) => order.isSelected === true && order.trackingNumber !== null,
      );
      console.log("CreateManifest shipmentsForManifest", shipmentsForManifest);
      const shipmentIds = [...new Set(shipmentsForManifest.map((shipment) => shipment.shipmentId))];
      shipmentService
        .CreateManifest(shipmentIds, userFromStore.user, locationFromStore, courierFromStore)
        .then((response) => {
          console.log("CreateManifest response", response);

          if (response.status === 200) {
            console.log("CreateManifest success response", response);
            console.log("CreateManifest success response.data", response.data);
            setProcessingModal(false);
            setShowManifestResponse(true);
            setManifestResponseMessage(response.data.order_id);
            refreshFromDb();
            dispatch(allActions.shipmentActions.setShipmentGridChkAll(false));
          } else if (response.status === 401) {
            dispatch(allActions.userActions.logOut());
            sessionService.SetSessionExpiry();
          } else if (response.status === 500) {
            setProcessingModal(false);
            setErrorMessage(`${TextConfig.MANIFEST_CREATION_FAILED}, ${response.data}`);
            setError(true);
          } else if (response.response.status === 401) {
            dispatch(allActions.userActions.logOut());
            sessionService.SetSessionExpiry();
          } else {
            setProcessingModal(false);
            console.log("CreateManifest behaved differently please check ");
          }
        });
    }
  };

  const manifestButtonHandler = () => {
    if (!validationHelper.isValidLocation(locationFromStore)) return false;

    const shipmentsForManifest = selectedShipmentsFromStore.filter(
      (order) => order.isSelected === true && order.trackingNumber !== null,
    );

    if (shipmentsForManifest.length === 0) {
      alert(" selected order shipment no is not generated or havent selected any eligible shipment");
    } else {
      console.log("manifestButtonHandler else condition");
      const manifestDetails = ManifestHelper.GetManifestAlertDetails(selectedShipmentsFromStore, shipmentsForManifest);
      console.log("var manifestDetails", manifestDetails);
      setManifestDetails(manifestDetails);
      setManifestModal(true);
    }
  };

  //  switch courier drop down selected
  //PostageUpdateModalCallBack will be triggered after confirmation
  const switchCourierCallBack = (props) => {
    console.log("ShipmentAction switchCourierCallBack", props);
    console.log("ShipmentAction switchCourierCallBack selectedShipmentsFromStore", selectedShipmentsFromStore);

    const selectedOrders = ListHelper.GetisSelectedTrueOrders(selectedShipmentsFromStore);
    if (selectedOrders.length === 0) {
      alert("you haven`t selected any orders or might have selected order with tracking number");
    } else {
      setPostageUpdateModalMsg(`Are you sure to switch for ${selectedOrders.length} order[s]`);
      setPostageSelected(props);
      setPostageUpdateModal(true);
    }
  };

  const SubmitPickUpCallBack = (props) => {
    console.log("SubmitPickUpCallBack props", props);
    if (props.action === CLICK_ACTION.CANCEL) {
      console.log("SubmitPickUpCallBack action cancelled");
      setSubmitPickUpModal(false);
    } else if (props.action === CLICK_ACTION.OK) {
      console.log("SubmitPickUpCallBack action Ok");
      setSubmitPickUpModal(false);
      const pickUpOrders = ListHelper.GetisSelectedTrueOrders(selectedShipmentsFromStore);
      const pickupDetails = PickUpService.GetPickUpDetails(pickUpOrders, props.PickUpComment, currentuser.firstName);
      console.log("submit pickup pickupPostObject ", pickupDetails);

      setCommonProgressModalTitle("Processing pickup please wait...........");
      setCommonProgressModal(true);

      PickUpService.SubmitPickUp(pickupDetails).then((response) => {
        console.log("SubmitPickUp pickupDetails" + JSON.stringify(response));
        setCommonProgressModal(false);
        if (response.status === 200) {
          ClearCheckBoxHeader();
          setAlertModalMessage("Pickup processed successfully");
          setShowAlertModal(true);
          refreshFromDb();
        } else if (response.status === 401) {
          dispatch(allActions.userActions.logOut());
          sessionService.SetSessionExpiry();
        } else {
          console.log("PickUpService.SubmitPickUp error", response.message);
          setProcessingModal(false);
        }
      });
    }
  };

  const PostageUpdateModalCallBack = (props) => {
    console.log("PostageUpdateModalCallBack action", props);
    if (props.action === false) {
      setPostageUpdateModal(false);
      setPostageUpdateModalMsg("");
      return false;
    } else if (props.action === true) {
      setPostageUpdateModal(false);
      setPostageUpdateModalMsg("");

      setCommonProgressModalTitle(`Updating courier details to ${props.postage}`);
      setCommonProgressModal(true);
      console.log("PostageUpdateModalCallBack selectedShipmentsFromStore : ", selectedShipmentsFromStore);
      const selectedOrders = ListHelper.GetisSelectedTrueOrders(selectedShipmentsFromStore);
      const updateObj = PostageHelper.UpdatePostageObject(selectedOrders, props.postage);
      console.log("switchCourierCallBack updateObj : ", updateObj);
      PostageService.UpdatePostage(updateObj).then((response) => {
        console.log("PostageService.UpdatePostage response", JSON.stringify(response));
        if (response.status === 200) {
          setCommonProgressModalTitle("");
          setCommonProgressModal(false);
          setAlertModalMessage("Update postage success");
          setShowAlertModal(true);
          refreshFromDb();
          ClearCheckBoxHeader();
          console.log("PostageService.UpdatePostage success", JSON.stringify(response));
        } else if (response.status === 401) {
          dispatch(allActions.userActions.logOut());
          sessionService.SetSessionExpiry();
        } else if (response.response.status === 401) {
          console.log("PostageService.UpdatePostage response.response.status===401", JSON.stringify(response.response));
          dispatch(allActions.userActions.logOut());
          sessionService.SetSessionExpiry();
        } else {
          console.log("PostageService.UpdatePostage error", JSON.stringify(response));
        }
      });
    }
  };

  const ManifestModalCallBack = () => {
    console.log("inside ManifestModalCallBack");
    setShowManifestResponse(false);
  };

  const closeCancelModel = (props) => {
    console.log("ShipmentGrid closeCancelModel props", props);
    if (
      props.action === CLICK_ACTION.CLOSE &&
      (props.source === CLICK_ACTION.ALERTMODALCLOSE || props.source === CLICK_ACTION.ALERTMODALOK)
    ) {
      setShowAlertModal(false);
    }
  };

  return (
    <div className="row align-items-end">
      <div className="col-sm-5">
        <div className="row">
          <div className="col-auto">
            <LocationFilter selected={locationFromStore} callBack={handleFilterLocation}></LocationFilter>
          </div>
          <div className="col-sm-8">
            <div className="form-group">
              <label htmlFor="inputSearch" className="col-form-label-sm mb-0">
                Search
              </label>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  id="inputSearch"
                  placeholder="Shipment, Account or Name"
                  value={searchTextFromStore}
                  onChange={(e) => dispatch(allActions.shipmentActions.setSearchText(e.target.value))}
                />
                {!!searchTextFromStore && (
                  <div className="input-group-append">
                    <button
                      className="btn btn-outline-secondary"
                      type="submit"
                      onClick={() => dispatch(allActions.shipmentActions.setSearchText(""))}
                    >
                      <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-7">
        <div className="row justify-content-end pb-3">
          <div className="col-auto">
            <button type="button" onClick={() => refreshFromDb()} className="btn btn-outline-dark">
              <FontAwesomeIcon icon={faSync} className="mr-2"></FontAwesomeIcon>
              Refresh
            </button>
          </div>
          <div className="col-auto">
            <button type="button" onClick={() => setShowRefreshModal(true)} className="btn btn-outline-dark">
              <FontAwesomeIcon icon={faExchangeAlt} className="mr-2"></FontAwesomeIcon>
              Sync D365
            </button>
          </div>
          <div className="col-auto">
            <SwitchCourierDropdown shipmentActionCallback={switchCourierCallBack}></SwitchCourierDropdown>
          </div>
          <div className="col-auto">
            <button
              type="button"
              onClick={manifestButtonHandler}
              id="buttonCreateManifest"
              className="btn btn-dark"
              disabled={!showManifestFromStore}
            >
              <FontAwesomeIcon icon={faClipboard} className="mr-2"></FontAwesomeIcon>
              Create Manifest
            </button>
          </div>
        </div>
      </div>
      <CreateManifestModal
        show={showManifestModal}
        onClick={manifestModalCallBack}
        onHide={manifestModalCallBack}
        details={manifestDetails}
      ></CreateManifestModal>
      <ManifestResponseModal
        show={showManifestReponseModal}
        message={manifestResponseMessage}
        location={locationFromStore}
        courier={courierFromStore}
        closeModal={ManifestModalCallBack}
      ></ManifestResponseModal>
      <PostageUpdateModal
        show={showPostageUpdateModal}
        message={postageUpdateModalMsg}
        PostageUpdateModalCallBack={PostageUpdateModalCallBack}
        postageName={postageSelected}
      ></PostageUpdateModal>
      <CommonProgressModal title={commonProgressModalTitle} show={showCommonProgressModal}></CommonProgressModal>
      <RefreshModal
        show={showRefreshModal}
        onRefresh={(refreshOptions) => {
          rootprops.callBack(refreshOptions);
          setShowRefreshModal(false);
        }}
        onCancel={() => setShowRefreshModal(false)}
      />
      {showSubmitPickUpModal && (
        <SubmitPickUpModal
          show={showSubmitPickUpModal}
          shipments={submitPickUpData}
          callBack={SubmitPickUpCallBack}
          onHide={SubmitPickUpCallBack}
        ></SubmitPickUpModal>
      )}
      {showProcessingModal && <ProcessingModal show={showProcessingModal} title={processingModalMsg}></ProcessingModal>}
      {showAlertModal && (
        <AlertModal show={showAlertModal} message={alertModalMessage} closeModal={closeCancelModel}></AlertModal>
      )}
      {showError && <ErrorModal show={showError} message={errorMessage} closeModal={closeCancelModal}></ErrorModal>}
    </div>
  );
};
export default ShipmentActionBar;
