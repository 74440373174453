import axios from "axios";
import * as UrlConfig from "../AppConstant/UrlConfig";

export const UpdatePostage = async (postageUpdate) => {
  const authToken = sessionStorage.getItem("AuthToken");
  console.log("inside the UpdatePostage  method");

  try {
    return await axios.post(UrlConfig.SWITCH_COURIER_URL, JSON.stringify(postageUpdate), {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });
  } catch (exception) {
    return exception;
  }
};
