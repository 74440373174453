import React from "react";

const Checkbox = (props) => {
  return (
    <div>
      <input
        type="checkbox"
        className="CheckboxColor"
        value={props.value}
        id={props.id}
        checked={props.isSelected}
        onChange={props.onCheckBoxChange}
      ></input>
    </div>
  );
};
export default Checkbox;
