import React, { useState } from "react";
import "./LocationFilter.css";

const LocationFilter = (props) => {
  console.log("LocationFilter props.selected", props.selected);
  const [selectedOption, setSeletedOption] = useState(props.selected);

  const changeHandler = (event) => {
    console.log("LocationFilter changeHandler event", event.target.value);
    setSeletedOption(event.target.value);
    props.callBack(event);
  };

  return (
    <div className="form-group">
      <label htmlFor="inputLocation" className="col-form-label-sm mb-0">
        Location
      </label>
      <select id="inputLocation" value={selectedOption} className="form-control"
              onChange={changeHandler}>
        <option value="All Locations">All Locations</option>
        <option value="8 Meeks Road">8 Meeks Road</option>
        <option value="66 Smith Street">66 Smith Street</option>
        <option value="38 Martin Place">38 Martin Place</option>
      </select>
    </div>
  );
};
export default LocationFilter;
