export const GetManifestAlertDetails = (shipmentOrders, selectedShipmentIds) => {
  const totalCount = shipmentOrders.length;
  console.log("GetManifestAlertDetails shipmentOrders : ", shipmentOrders);
  console.log("GetManifestAlertDetails totalCount : ", totalCount);

  const validShipments = shipmentOrders.filter((order) => order.trackingNumber !== null);
  const unProcessed = totalCount - validShipments.length;

  console.log("GetManifestAlertDetails unProcessed : ", unProcessed);
  console.log("GetManifestAlertDetails validShipments : ", validShipments);
  return {
    totalCount: totalCount,
    validShipments: validShipments.length,
    selectedShipments: selectedShipmentIds.length,
    unProcessed: unProcessed,
  };
};
