import React from "react";
import "bootstrap/dist/css/bootstrap.css";

const ShipmentGridStatus = (props) => {
  if (props.trackingNo !== null) {
    return <span className="badge badge-pill badge-success">{props.trackingNo}</span>;
  } else if (props.status !== null) {
    return <span className="badge badge-danger badge-message">{props.status}</span>;
  } else {
    return <div></div>;
  }
};
export default ShipmentGridStatus;
