import axios from "axios";
import * as UrlConfig from "../AppConstant/UrlConfig";

export const DownLoadPdf = async (orderId, location, courier) => {
  console.log("DownLoadPdf courier", courier);
  const url = GetOrderSummaryUrl(courier.toLowerCase());
  console.log("DownLoadPdf orderId", orderId);
  console.log("DownLoadPdf url", url);

  const authToken = sessionStorage.getItem("AuthToken");

  const orderSumaryRequest = {
    orderId: orderId,
    location: location,
    courier: courier,
    user: "",
  };

  try {
    return await axios.post(url, JSON.stringify(orderSumaryRequest), {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      responseType: "blob",
    });
  } catch (exception) {
    return exception;
  }
};

const GetOrderSummaryUrl = (courier) => {
  switch (courier) {
    case "startrack":
      return UrlConfig.STARTRACK_ORDERSUMMARY_URL;
    case "ausexpost":
    case "auspost":
      return UrlConfig.ORDERSUMMARY_URL;
  }
};
