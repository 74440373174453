import userReducer from "./userReducer";
import shipment from "./shipmentReducer";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  userReducer,
  shipment,
});

export default rootReducer;
