import React, { useState } from "react";

import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";

export const RefreshModal = (props) => {
  const [datePickerStartDate, setDatePickerStartDate] = useState(new Date());
  const [datePickerEndDate, setDatePickerEndDate] = useState(new Date());

  const handleRefresh = () =>
    props.onRefresh({
      axRefresh: true,
      startDate: datePickerStartDate,
      endDate: datePickerEndDate,
    });

  return (
    <Modal centered show={props.show} onHide={() => props.onCancel()}>
      <Modal.Header closeButton>
        <Modal.Title>Sync D365</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <p>New shipments will be fetched from D365 for the selected date range. This may take some time.</p>
          <div className="form-group row">
            <label htmlFor="inputStartDate" className="col-4 col-form-label">
              Start Date
            </label>
            <div className="col-8">
              <DatePicker
                id="inputStartDate"
                className="form-control"
                selected={datePickerStartDate}
                filterDate={(maxDate) => {
                  return new Date() > maxDate;
                }}
                dateFormat="dd/MM/yyyy"
                onChange={(date) => setDatePickerStartDate(date)}
              ></DatePicker>
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="inputEndDate" className="col-4 col-form-label">
              End Date
            </label>
            <div className="col-8">
              <DatePicker
                id="inputEndDate"
                className="form-control"
                selected={datePickerEndDate}
                filterDate={(maxDate) => {
                  return new Date() > maxDate;
                }}
                dateFormat="dd/MM/yyyy"
                onChange={(date) => setDatePickerEndDate(date)}
              ></DatePicker>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="container">
          <div className="row">
            <div className="col">
              <button className="btn btn-outline-dark" onClick={() => props.onCancel()}>
                Cancel
              </button>
            </div>
            <div className="col">
              <div>
                <button className="btn btn-dark float-right" onClick={handleRefresh}>
                  Sync
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
