import { DIMENSION } from "../AppConstant/ShippingConstants";

// Return a shipment object with updatedAddress to pass to
export const GetUpdatedShipment = (address, shipments) => {
  console.log("shipmentHelper GetUpdatedShipment address:" + JSON.stringify(address));
  try {
    const tempOrders = [...shipments];
    const shipmentObj = tempOrders.find((obj) => {
      return obj.salesShipmentNum === address.salesShipmentNum;
    });
    shipmentObj.toCity = address.place;
    shipmentObj.toState = address.state;
    shipmentObj.toPostcode = address.postCode;
    shipmentObj.toStreet = address.street;
    return shipmentObj;
  } catch (exception) {
    console.log("SalesOrderService.UpdateAddress exception", JSON.stringify(exception));
    return exception;
  }
};

// Return a shipment object with updated dimensions width or size
export const GetUpdatedDimension = (dimension, shipments, user) => {
  console.log("shipmentHelper GetUpdatedDimension user:" + JSON.stringify(user));
  console.log("shipmentHelper GetUpdatedDimension dimension:" + JSON.stringify(dimension));
  try {
    const tempOrders = [...shipments];
    const shipmentObj = tempOrders.find((obj) => {
      return obj.salesShipmentNum === dimension.salesShipmentNum;
    });
    if (dimension.type === DIMENSION.WEIGHT) {
      shipmentObj.totalweight = parseFloat(dimension.weight);
    } else {
      shipmentObj.boxSizeX = parseFloat(dimension.length);
      shipmentObj.boxSizeY = parseFloat(dimension.width);
      shipmentObj.boxSizeZ = parseFloat(dimension.height);
    }

    return {
      dimensionType: dimension.type,
      SalesShipmentViewModel: shipmentObj,
      user: user.firstName,
    };
  } catch (exception) {
    console.log("SalesOrderService.GetUpdatedDimension exception", JSON.stringify(exception));
    return exception;
  }
};

//Method to
export const GetCustomerInfo = (props) => {
  console.log("shipmentHelper GetCustomerInfo props ", props);
  return {
    name: props.name,
    alias: props.alias,
    mapNo: props.mapNo,
    salesShipmentNum: props.salesShipmentNum,
  };
};

//Prepare the shipment object to delete based on the props
export const GetShipmentToDelete = (props) => {
  console.log("SalesOrderService.GetShipmentToDelete props", props);
  return {
    salesShipmentNo: props.shipmentNo,
    deleteReason: props.deleteReason,
    isDeleted: true,
    deletedDate: new Date(),
  };
};

export const RemoveShipment = (shipmentsFromStore, shipmentNo) => {
  try {
    const tempOrders = [...shipmentsFromStore];
    const indexToRemove = tempOrders.findIndex((orders) => orders.salesShipmentNum === shipmentNo);
    console.log("indexToRemove" + indexToRemove);
    tempOrders.splice(indexToRemove, 1);
    return tempOrders;
  } catch (exception) {
    console.log("SalesOrderService.RemoveShipment exception", JSON.stringify(exception));
    return exception;
  }
};

//Create the Rollback bject nased on the props
export const GetRollBackData = (props) => {
  try {
    return {
      salesShipmentNo: props.shipmentNo,
      deleteReason: "",
      isDeleted: true,
      deletedDate: new Date(),
    };
  } catch (exception) {
    console.log("shipmentHelper  GetRollBackData exception", JSON.stringify(exception));
    return exception;
  }
};
