import axios from "axios";
import * as UrlConfig from "../AppConstant/UrlConfig";

export const GetManifestSummary = async (manifestParam) => {
  console.log("GetManifestSummary", JSON.stringify(manifestParam));
  const authToken = sessionStorage.getItem("AuthToken");
  console.log("inside the UpdatePostage  method");

  try {
    return await axios.post(UrlConfig.MANIFEST_SUMMARY_URL, JSON.stringify(manifestParam), {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });
  } catch (exception) {
    if (!exception.response) {
      console.log("GetManifestSummary !exception.response ", JSON.stringify(exception));
      return exception;
    }
    return exception.response;
  }
};

//Getting the shipment details for the amnifestid
export const GetShipmentsByManifestId = async (manifestId) => {
  const authToken = sessionStorage.getItem("AuthToken");
  console.log("inside the GetShipmentsByManifestId  method", manifestId);
  try {
    return await axios.post(UrlConfig.MANIFEST_DETAILS_URL, JSON.stringify(manifestId), {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      params: { manifestId: manifestId },
    });
  } catch (exception) {
    return exception;
  }
};
