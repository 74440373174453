import React, { Fragment, useState } from "react";
import * as shippingConst from "../AppConstant/ShippingConstants";
import { CLICK_ACTION, DIMENSION } from "../AppConstant/ShippingConstants";
import * as TextConfig from "../AppConstant/TextConfig";
import { COMPONENT } from "../AppConstant/ShippingConstants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeleteShipmentModal from "../Home/DeleteShipmentModal";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.css";
import * as LocationService from "./LocationService";
import * as shipmentService from "./shipmentService";
import Checkbox from "../CustomControl/Checkbox";
import ProcessingModal from "../CustomControl/ProcessingModal";
import { faEllipsisV, faFilter, faFilePdf, faPencilAlt, faEraser, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import AlertModal from "../CustomControl/AlertModal";
import { useSelector, useDispatch } from "react-redux";
import { TableVirtuoso } from "react-virtuoso";
import CustomerAlias from "../Customer/CustomerAlias";
import * as shipmentFilter from "./shipmentFilter";
import * as shipmentHelper from "./shipmentHelper";
import allActions from "../redux/actions";
import LocationUpdateDropdown from "./LocationUpdateDropdown";
import AddressView from "./AddressView";
import AddressModal from "./AddressModal";
import ShipmentGridStatus from "./ShipmentGridStatus";
import "./ShipmentGrid.css";
import DimensionModal from "./DimensionModal";

import ErrorModal from "../CustomControl/ErrorModal";

import * as sessionService from "../Session/StorageService";

const ShipmentGrid = (shipmentGridProps) => {
  const dispatch = useDispatch();
  const selectAllFromStore = useSelector((state) => state.shipment.shipmentGridChkAll);

  console.log("ShipmentGrid  selectAllFromStore", selectAllFromStore);

  const userFromStore = useSelector((state) => state.userReducer);
  const allShipmentFromStore = useSelector((state) => state.shipment.allShipments);
  const selectedShipmentsFromStore = useSelector((state) => state.shipment.selected);
  const courierFromStore = useSelector((state) => state.shipment.courier);
  const [isLoading, setIsLoading] = useState(false);
  const [processingMessage, setProcessingMesage] = useState("Fetching shipments........");

  const [, setCommonProgressModal] = useState(false);
  const [, setCommonProgressProps] = useState({});
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [alertModalProps, setAlertModalProps] = useState({});
  const [showCustomerAlias, setShowCustomerAlias] = useState(false);
  const [customerAliasData, setCustomerAliasData] = useState({});

  /* Address Edit Modal   */
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [addressModalContent, setaddressModalContent] = useState({});
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [shipmentToDelete, setShipmentToDelete] = useState("");

  /* Dimensions(size & weight)  Edit Modal    */
  const [showDimensionModal, setDimensionModal] = useState(false);
  const [dimension, setDimension] = useState({});
  const [showError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const closeCancelModal = (props) => {
    console.log("inside CLICK_ACTION.ERRORMODALCLOSE", props);
    if (props.action === CLICK_ACTION.CLOSE && props.source === CLICK_ACTION.ERRORMODALCLOSE) {
      setErrorMessage("");
      setError(false);
    }
  };

  //select all checkbox
  const checkBoxSelectAllHandler = (flag) => {
    const status = flag.target.checked;
    console.log("ShipmentGrid  checkBoxSelectAllHandler flag", status);
    dispatch(allActions.shipmentActions.setShipmentGridChkAll(status));
    const updatedShipments = shipmentFilter.ToggleSelectAll(selectedShipmentsFromStore, status);
    console.log("ShipmentGrid  checkBoxSelectAllHandler updatedShipments", updatedShipments);
    dispatch(allActions.shipmentActions.setSelected(updatedShipments));
  };

  //CallBack from the LocationDropdown once the new address is selected
  const locationUpdateCallBack = (props) => {
    console.log("ShipmentGrid LocationUpdateDropdown callback event", props.newLocation);
    let newAddress = shippingConst.FROM_ADDRESS[props.newLocation];
    let salesShipmentNum = props.salesShipmentNum;
    console.log(
      "ShipmentGrid LocationUpdateDropdown callback newAddress , salesShipmentNum",
      newAddress,
      salesShipmentNum,
    );
    const tempOrders = LocationService.GetUpdatedOrder(selectedShipmentsFromStore, newAddress, salesShipmentNum);
    console.log("FromAddressChangeHandler updatedOrders", tempOrders);
    const updateShipmentArray = LocationService.GetUpdateArray(tempOrders, salesShipmentNum);

    const popUpMsg = {
      title: `Updating  Location  for ShipmentNumber : ${salesShipmentNum}`,
      message: `Updating the Location details for ShipmentNumber : ${salesShipmentNum}`,
    };
    setCommonProgressProps(popUpMsg);
    setCommonProgressModal(true);
    LocationService.UpdateLocation(updateShipmentArray).then((response) => {
      setCommonProgressModal(false);
      if (response.status === 200) {
        setAlertModalProps({ message: "Location updated successfully" });
        setShowAlertModal(true);
        shipmentGridProps.callBack({
          source: "shipmentGrid",
          axRefresh: false,
          startDate: new Date(),
          endDate: new Date(),
          courierFromStore: courierFromStore,
        });
      } else if (response.response.status === 401) {
        dispatch(allActions.userActions.logOut());
        sessionService.SetSessionExpiry();
      } else {
        setAlertModalProps({
          message: "Update didn't go through, please try again",
        });
        setShowAlertModal(true);
      }
    });
  };

  //common callback from modals for cancel and close[X] button click
  const closeCancelModel = (props) => {
    console.log("ShipmentGrid closeCancelModel props", props);
    if (props.action === "close" && (props.source === "AlertModalClose" || props.source === "AlertModalOK")) {
      setShowAlertModal(false);
    }
  };

  const onCheckBoxChangeHandler = (salesShipmentNum) => {
    console.log("ShipmentGrid  onCheckBoxChangeHandler salesShipmentNum", salesShipmentNum);
    const updatedShipments = shipmentFilter.SetSelectedFlag(selectedShipmentsFromStore, salesShipmentNum);
    console.log("ShipmentGrid  onCheckBoxChangeHandler updatedShipments", updatedShipments);
    dispatch(allActions.shipmentActions.setSelected(updatedShipments));
  };

  // call back from address container on edit button click (AddressView)
  const addressEditCallBack = (props) => {
    console.log("ShipmentGrid addressEditCallBack props", props);
    console.log("ShipmentGrid JSON.parse(props)", JSON.parse(JSON.stringify(props)));
    setaddressModalContent({
      message: "Update Address",
      address: JSON.parse(JSON.stringify(props)),
    });
    setShowAddressModal(true);
  };

  // call back from address Edit modal (AddressModal) on click or cancel/edit
  const addressModalCallBack = (props) => {
    console.log("ShipmentGrid addressModalCallBack  Edit props", props);
    console.log("ShipmentGrid CLICK_ACTION.CANCEL", CLICK_ACTION.CANCEL);
    setShowAddressModal(false);
    if (props.action === CLICK_ACTION.UPDATE) {
      setProcessingMesage("Please wait ....Updating address");
      setIsLoading(true);
      AddressUpdateHandler(props.address);
    } else if (props.action === CLICK_ACTION.CANCEL) {
      setShowAddressModal(false);
    }
  };

  const AddressUpdateHandler = (address) => {
    console.log("address:" + JSON.stringify(address));
    const shipmentObj = shipmentHelper.GetUpdatedShipment(address, selectedShipmentsFromStore);
    console.log("AddressUpdateHandler showAddressModal", showAddressModal);
    shipmentService.UpdateAddress(shipmentObj, userFromStore.user).then((response) => {
      console.log("AddressUpdateHandler.UpdateAddress response json", JSON.stringify(response));
      console.log("AddressUpdateHandler.UpdateAddress response response.response", response.response);
      if (response.status === 204) {
        setProcessingMesage("Please wait ....Updating address");
        setIsLoading(false);
        console.log("AddressUpdateHandler 204");
        shipmentGridProps.callBack({
          source: "shipmentGrid",
          axRefresh: false,
          startDate: new Date(),
          endDate: new Date(),
          courierFromStore: courierFromStore,
        });
        setAlertModalProps({ message: "Update Address Completed" });
        setShowAlertModal(true);
      } else if (response.response.status === 401) {
        dispatch(allActions.userActions.logOut());
        sessionService.SetSessionExpiry();
      } else if (response.response.status === 422) {
        shipmentPageCallBack();
        setIsLoading(false);
        console.log("UpdateAddress inside 422 response.response ", response.response);
        const errorMessage = response.response.data["shipmentPriceError"];
        setErrorMessage(`Update Address falied, ${errorMessage}`);
        setError(true);
      } else {
        console.log("AddressUpdateHandler.UpdateAddress else condition ", JSON.stringify(response));
      }
    });
  };

  // pencil icon against customer name is clicked (update customer alias data)
  const CustomerAliasClickHandler = (props) => {
    console.log("shipmentGrid CustomerAliasClickHandler props ", props);
    const customerInfo = shipmentHelper.GetCustomerInfo(props);
    setCustomerAliasData(customerInfo);
    setShowCustomerAlias(true);
  };

  const CustomerAliasCallBack = (props) => {
    console.log("CustomerAliasCallBack props" + JSON.stringify(props));
    console.log("CustomerAliasCallBack !props.action === CLICK_ACTION.CANCEL", !(props.action === CLICK_ACTION.CANCEL));
    setShowCustomerAlias(false);
    if (!(props.action === CLICK_ACTION.CANCEL)) {
      shipmentService.EditCustomerAlias(props.address, userFromStore.user).then((response) => {
        console.log("CustomerAliasCallBack status" + JSON.stringify(response));
        if (response.status === 200) {
          console.log("inside status = 200");
          shipmentPageCallBack();
          setAlertModalProps({
            message: "CustomerAlias updated successfully",
          });
          setShowAlertModal(true);
          console.log("CustomerAliasCallBack isLoading", isLoading);
        } else if (response.status === 401) {
          dispatch(allActions.userActions.logOut());
          sessionService.SetSessionExpiry();
        }
      });
      console.log("CustomerAliasCallBack props update" + JSON.stringify(props));
    }
  };

  const shipmentPageCallBack = () => {
    shipmentGridProps.callBack({
      source: COMPONENT.ShipmentGrid,
      axRefresh: false,
      startDate: new Date(),
      endDate: new Date(),
      courierFromStore: courierFromStore,
    });
  };

  const DeleteHandler = (props) => {
    console.log("setDeleteModal " + props);
    setShipmentToDelete(props.shipmentNo);
    setDeleteModal(props.flag);
  };

  const deleteModalCallBack = (props) => {
    console.log(" deleteModalCallBack props", props);
    setDeleteModal(false);
    if (props.action === CLICK_ACTION.DELETE) {
      const deleteShipmentObj = shipmentHelper.GetShipmentToDelete(props);
      console.log(" deleteModalCallBack deleteShipmentObj", deleteShipmentObj);
      shipmentService.DeleteShipment(deleteShipmentObj, userFromStore.user).then((response) => {
        console.log("Delete status" + JSON.stringify(response));
        if (response.status === 200) {
          console.log("inside deleteModalCallBack status = 200");
          const updatedAllShipments = shipmentHelper.RemoveShipment(allShipmentFromStore, props.shipmentNo);
          const finalOrders = shipmentHelper.RemoveShipment(selectedShipmentsFromStore, props.shipmentNo);
          dispatch(allActions.shipmentActions.setAll(updatedAllShipments));
          dispatch(allActions.shipmentActions.setSelected(finalOrders)); //reset the selectedSHipemtns with the new array after deletion
          const countByCourier = shipmentFilter.GetCountByCourier(updatedAllShipments);
          dispatch(allActions.shipmentActions.setCount(countByCourier));
          setAlertModalProps({ message: "Shipment deleted successfully" });
          setShowAlertModal(true);
        } else if (response.status === 401) {
          dispatch(allActions.userActions.logOut());
          sessionService.SetSessionExpiry();
        }
      });
    }

    console.log("this.state.shipmentOrders.length", selectedShipmentsFromStore.length);
  };

  const ShipmentRollback = (props) => {
    console.log("ShipmentRollback props" + JSON.stringify(props));
    const rollBackParam = shipmentHelper.GetRollBackData(props);
    console.log("ShipmentRollback rollBackParam  ", rollBackParam);
    shipmentService.RollbackShipment(rollBackParam, userFromStore).then((response) => {
      console.log("ShipmentRollback status" + JSON.stringify(response));
      if (response.status === 200) {
        console.log("isnide status = 200");
        shipmentPageCallBack();
        setAlertModalProps({ message: "Shipment roll back success" });
        setShowAlertModal(true);
      } else if (response.status === 401) {
        dispatch(allActions.userActions.logOut());
        sessionService.SetSessionExpiry();
      }
    });
  };

  // handler for edit weight and edit size
  const editDimensions = (props) => {
    console.log("editDimensions props" + JSON.stringify(props));
    setDimension(props);
    setDimensionModal(true);
  };

  const dimensionModalCallback = (props) => {
    setDimensionModal(false);
    const dimensionParam = shipmentHelper.GetUpdatedDimension(
      props.dimension,
      selectedShipmentsFromStore,
      userFromStore.user,
    );
    if (props.action === CLICK_ACTION.UPDATE && props.dimension.type === DIMENSION.WEIGHT) {
      shipmentService.UpdateDimension(dimensionParam).then((response) => {
        if (response.status === 200) {
          shipmentPageCallBack();
          displayConfirmation("Weight updated successfully", true);
        } else if (response.status === 401) {
          dispatch(allActions.userActions.logOut());
          sessionService.SetSessionExpiry();
        } else {
          shipmentPageCallBack();
          setErrorMessage("Update didn't go through, please check the status");
          setError(true);
        }
      });
    } else if (props.action === CLICK_ACTION.UPDATE && props.dimension.type === DIMENSION.SIZE) {
      shipmentService.UpdateDimension(dimensionParam).then((response) => {
        console.log("shipmentService.UpdateSize response", response);
        if (response.status === 200) {
          shipmentPageCallBack();
          displayConfirmation("Size updated successfully", true);
        } else if (response.status === 401) {
          dispatch(allActions.userActions.logOut());
          sessionService.SetSessionExpiry();
        } else {
          shipmentPageCallBack();
          setErrorMessage("Update didn't go through, please check the status");
          setError(true);
        }
      });
    }
  };

  // common message to displayConfirmation or alert user
  const displayConfirmation = (msg, flag) => {
    setAlertModalProps({ message: msg });
    setShowAlertModal(flag);
  };

  const renderTableHeader = () => {
    return (
      <>
        <th style={{ width: "2.5%" }}>#</th>
        <th style={{ width: "2.5%" }}>
          <Checkbox
            id={"chkSelectAll"}
            isSelected={selectAllFromStore}
            onCheckBoxChange={checkBoxSelectAllHandler}
          ></Checkbox>
        </th>
        <th style={{ width: "7.5%" }}>Account</th>
        <th style={{ width: "7.5%" }}>Invoice & Shipment #</th>
        <th style={{ width: "5%" }}>Courier Name</th>
        <th style={{ width: "8%" }}>
          <span>
            Location &nbsp; <FontAwesomeIcon icon={faFilter}></FontAwesomeIcon>
          </span>
        </th>
        <th style={{ width: "10%" }}>Name</th>
        <th style={{ width: "20%" }}>Address</th>
        <th style={{ width: "6%" }}>
          Size
          <br />
          (L,W,H)
        </th>
        <th style={{ width: "5%" }}>
          Cost
          <br />
          ($)
        </th>
        <th style={{ width: "6%" }}>
          Weight
          <br />
          (g)
        </th>
        <th style={{ width: "14%" }}>Tracking/Status</th>
        <th style={{ width: "6%" }}></th>
      </>
    );
  };

  const renderRowData = (order, index) => {
    const {
      customerAccount,
      customerName,
      toCity,
      toPostcode,
      toState,
      toStreet,
      boxSizeX,
      boxSizeY,
      boxSizeZ,
      salesShipmentNum,
      totalValue,
      shipmentPriceError,
      totalweight,
      isSelected,
      invoiceNumber,
      trackingNumber,
      labelUrl,
      fromStreet,
      postageCode,
      customerNameAlias,
    } = order;
    const isLocationDisabled = trackingNumber !== null;
    const formattedAliasName = customerNameAlias !== null ? `[${customerNameAlias}]` : "";
    return (
      <>
        <td style={{ textAlign: "center" }}>{index + 1}</td>
        <td>
          {isSelected}
          <Checkbox
            isSelected={isSelected}
            id={"chk" + salesShipmentNum}
            onCheckBoxChange={() => onCheckBoxChangeHandler(salesShipmentNum)}
          ></Checkbox>
        </td>
        <td>{customerAccount}</td>
        <td>
          {invoiceNumber}
          <br></br>
          {salesShipmentNum}
        </td>
        <td>{postageCode}</td>
        <td>
          <LocationUpdateDropdown
            location={fromStreet}
            disabled={isLocationDisabled}
            shipmentGridCallBack={locationUpdateCallBack}
            trackingNumber={trackingNumber}
            salesShipmentNum={salesShipmentNum}
          ></LocationUpdateDropdown>
        </td>
        <td>
          {customerName} {formattedAliasName}
          <FontAwesomeIcon
            id="editName"
            icon={faPencilAlt}
            size="1x"
            title="Edit Alias"
            color="red"
            onClick={() => {
              CustomerAliasClickHandler({
                name: customerName,
                alias: customerNameAlias,
                mapNo: customerAccount,
                salesShipmentNum: salesShipmentNum,
              });
            }}
            style={{ marginLeft: 4 }}
          ></FontAwesomeIcon>
        </td>
        <td>
          <AddressView
            shipmentGridCallBack={addressEditCallBack}
            salesShipmentNum={salesShipmentNum}
            toStreet={toStreet}
            toCity={toCity}
            toState={toState}
            toPostcode={toPostcode}
            shipmentPriceError={shipmentPriceError}
          />
        </td>
        <td>
          {"(" + boxSizeX + ", " + boxSizeY + "," + boxSizeZ + ")"}
          <FontAwesomeIcon
            id="editName"
            icon={faPencilAlt}
            size="1x"
            title="Edit Alias"
            color="red"
            onClick={() => {
              // noinspection JSSuspiciousNameCombination
              editDimensions({
                type: "size",
                length: boxSizeX,
                width: boxSizeY,
                height: boxSizeZ,
                salesShipmentNum: salesShipmentNum,
              });
            }}
            style={{ marginLeft: 4 }}
          ></FontAwesomeIcon>
        </td>
        <td>{totalValue}</td>
        <td>
          {totalweight}
          <FontAwesomeIcon
            id="editName"
            icon={faPencilAlt}
            size="1x"
            title="Edit Alias"
            color="red"
            onClick={() => {
              editDimensions({
                type: "weight",
                weight: totalweight,
                salesShipmentNum: salesShipmentNum,
              });
            }}
            style={{ marginLeft: 4 }}
          ></FontAwesomeIcon>
        </td>
        <td>
          <ShipmentGridStatus
            salesShipmentNum={salesShipmentNum}
            status={shipmentPriceError}
            trackingNo={trackingNumber}
            labelUrl={labelUrl}
            callback={ShipmentRollback}
          ></ShipmentGridStatus>
        </td>
        <td className="archive align-middle">
          <div className="row align-items-center justify-content-end pr-2">
            {trackingNumber && (
              <a
                href={labelUrl}
                target="_blank"
                rel="noreferrer"
                title="Download Label"
                className="btn btn-outline-light"
                style={{ borderColor: "transparent" }}
              >
                <FontAwesomeIcon size="1x" icon={faFilePdf} color="red" />
              </a>
            )}
            <div className="dropdown">
              <button
                className="btn btn-outline-light"
                style={{ borderColor: "transparent" }}
                type="button"
                id="dropdownMenu2"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                title="More actions"
              >
                <FontAwesomeIcon size="1x" icon={faEllipsisV} color="grey"></FontAwesomeIcon>
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                {trackingNumber && (
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() => ShipmentRollback({ shipmentNo: salesShipmentNum })}
                  >
                    <FontAwesomeIcon size="1x" icon={faEraser} className="mr-2" />
                    Rollback
                  </button>
                )}
                <button
                  className="dropdown-item"
                  type="button"
                  onClick={() => DeleteHandler({ flag: true, shipmentNo: salesShipmentNum })}
                >
                  <FontAwesomeIcon size="1x" icon={faTrashAlt} className="mr-2" />
                  Delete
                </button>
              </div>
            </div>
            <DeleteShipmentModal
              show={showDeleteModal}
              shipmentNo={shipmentToDelete}
              onClick={deleteModalCallBack}
              onHide={deleteModalCallBack}
            ></DeleteShipmentModal>
          </div>
        </td>
      </>
    );
  };

  return (
    <div>
      {!isLoading ? (
        <div className="table-responsive gridHolderShipmentGrid">
          <TableVirtuoso
            id="ShipmentGrid"
            className="ShipmentGrid table"
            style={{ tableLayout: "fixed", width: "100%" }}
            data={selectedShipmentsFromStore}
            fixedHeaderContent={() => (
              <tr style={{ backgroundColor: "#454545", color: "white" }}>{renderTableHeader()}</tr>
            )}
            itemContent={(index, order) => renderRowData(order, index)}
          />
          <AlertModal
            show={showAlertModal}
            message={alertModalProps.message}
            closeModal={closeCancelModel}
          ></AlertModal>

          {showAddressModal && (
            <AddressModal
              show={showAddressModal}
              address={addressModalContent.address}
              callBack={addressModalCallBack}
              message={addressModalContent.message}
            ></AddressModal>
          )}

          {showCustomerAlias && (
            <CustomerAlias
              callBack={CustomerAliasCallBack}
              address={customerAliasData}
              show={showCustomerAlias}
            ></CustomerAlias>
          )}

          {showDimensionModal && (
            <DimensionModal
              show={showDimensionModal}
              dimension={dimension}
              title={TextConfig.EDIT_SIZE_TITLE}
              callBack={dimensionModalCallback}
            ></DimensionModal>
          )}
          {showError && <ErrorModal show={showError} message={errorMessage} closeModal={closeCancelModal}></ErrorModal>}
        </div>
      ) : (
        <Fragment>
          <ProcessingModal show={isLoading} title={processingMessage}></ProcessingModal>
        </Fragment>
      )}
    </div>
  );
};

export default ShipmentGrid;
