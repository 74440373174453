import React from "react";
import "./LocationUpdateDropdown.css";

const LocationUpdateDropdown = (props) => {
  const changeHandler = (event) => {
    let selectedLocation = event.target.value;
    let salesShipmentNum = event.target.name;
    props.shipmentGridCallBack({
      newLocation: selectedLocation,
      salesShipmentNum: salesShipmentNum,
    });
  };

  return (
    <div>
      <select
        value={props.location}
        disabled={props.disabled}
        className="locationUpdateDropdown"
        id={`drp${props.salesShipmentNum}`}
        name={props.salesShipmentNum}
        onChange={changeHandler}
      >
        <option value="8 Meeks Road">8 Meeks Road</option>
        <option value="66 Smith Street">66 Smith Street</option>
        <option value="38 Martin Place">38 Martin Place</option>
      </select>
    </div>
  );
};
export default LocationUpdateDropdown;
