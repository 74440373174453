import { getConfig } from "./Config";

const API_ROOT = getConfig().apiRoot;
console.log(`API_ROOT: ${API_ROOT}`);

export const GET_SHIPMENT_URL = `${API_ROOT}/SalesShipment/shipment`;
export const UPDATE_ADDRESS_URL = `${API_ROOT}/SalesShipment/updateaddress`;
export const UPDATE_WEIGHT_URL = `${API_ROOT}/SalesShipment/weight`;
export const UPDATE_SIZE_URL = `${API_ROOT}/SalesShipment/size`;
export const UPDATE_DIMENSION_URL = `${API_ROOT}/SalesShipment/dimension`;

export const DELETE_SHIPMENT_URL = `${API_ROOT}/SalesShipment/deleteshipment`;

export const CREATE_AUSPOST_SHIPMENT_URL = `${API_ROOT}/AuspostShipment/create`;
export const CREATE_STARTRACK_SHIPMENT_URL = `${API_ROOT}/StarTrackShipment/create`;
export const LOGIN_URL = `${API_ROOT}/Users/authenticate`;
export const AUTH_URL = `${API_ROOT}/api/Authentication/authenticate`;
export const CREATE_MANIFEST_URL = `${API_ROOT}/AuspostShipment/manifest`;
export const STARTRACK_CREATE_MANIFEST_URL = `${API_ROOT}/StarTrackOrder/create`;
export const ORDERSUMMARY_URL = `${API_ROOT}/AuspostShipment/ordersummary`;

export const STARTRACK_ORDERSUMMARY_URL = `${API_ROOT}/StarTrackOrder/ordersummary`;

export const UPDATE_POSATGE_URL = `${API_ROOT}/SalesShipment/updatepostage`;
export const SWITCH_COURIER_URL = `${API_ROOT}/SalesShipment/switchcourier`;

export const SUBMIT_PICKUP_URL = `${API_ROOT}/SalesShipment/submitpickup`;
export const UPDATE_LOCATION_URL = `${API_ROOT}/SalesShipment/updatelocation`;

export const MANIFEST_SUMMARY_URL = `${API_ROOT}/SalesShipment/manifest`;
export const MANIFEST_DETAILS_URL = `${API_ROOT}/SalesShipment/manifestdetails`;
export const ROLLBACK_SHIPMENT_URL = `${API_ROOT}/SalesShipment/rollbackshipment`;
export const EDIT_CUSTOMERALIAS_URL = `${API_ROOT}/SalesShipment/customeralias`;
