import React, { Fragment } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import HomeTopNavBar from "./Home/HomeTopNavBar";
import SecondNavbar from "./Home/SecondNavbar";
import ManifestTab from "./ManifestTab/ManifestTab";
import LoginNew from "./Login/LoginNew";
import { useSelector } from "react-redux";
import ShipmentPage from "./shipment/ShipmentPage";
import { AuthenticationRequired } from "./components/auth/AuthenticationRequired";

const Main = () => {
  const isLoggedIn = useSelector((state) => state.userReducer.loggedIn);

  return (
    <div>
      <BrowserRouter>
        {isLoggedIn && (
          <>
            <HomeTopNavBar user={""}></HomeTopNavBar> <SecondNavbar></SecondNavbar>
          </>
        )}
        <Routes>
          <Route
            path="/"
            element={
              <AuthenticationRequired>
                <ShipmentPage />
              </AuthenticationRequired>
            }
          />
          <Route path="/Login" element={<LoginNew />} />
          <Route
            path="/ManifestTab"
            element={
              <AuthenticationRequired>
                <ManifestTab />
              </AuthenticationRequired>
            }
          />
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default Main;
