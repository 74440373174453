import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faHourglassEnd } from "@fortawesome/free-solid-svg-icons";
import { CLICK_ACTION } from "../AppConstant/ShippingConstants";
import { Modal } from "react-bootstrap";
import * as TextConfig from "../AppConstant/TextConfig";
import "bootstrap/dist/css/bootstrap.css";
import * as CssConfig from "../AppConstant/CSSConstant";

const ErrorModal = (props) => {
  console.log("ErrorModal Props", props);
  const closeModal = () => {
    props.closeModal({
      source: CLICK_ACTION.ERRORMODALCLOSE,
      action: CLICK_ACTION.CLOSE,
      trigger: props.trigger,
    });
  };
  return (
    <Modal centered show={props.show} onHide={closeModal}>
      <Modal.Header closeButton>
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="d-flex justify-content-center">
                {props.trigger === TextConfig.SESSION_EXPIRY ? (
                  <FontAwesomeIcon icon={faHourglassEnd} color="red" size="3x">
                    {" "}
                  </FontAwesomeIcon>
                ) : (
                  <FontAwesomeIcon icon={faExclamationCircle} color="red" size="3x">
                    {" "}
                  </FontAwesomeIcon>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className={CssConfig.CENTER_TEXT_BOLD}>{props.message}</div>
      </Modal.Body>
      <Modal.Footer>
        <div className="container">
          <div className="row">
            <div className="col">
              <div>
                <button className="btn btn-secondary btn-sm float-right" onClick={closeModal}>
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
export default ErrorModal;
