import React, { Fragment, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import loginlogo from "./Pallion_logo.jpg";

import "./Login.css";

import { useDispatch, useSelector } from "react-redux";
import allActions from "../redux/actions";
import * as LoginService from "./LoginService";
import * as shippingConst from "../AppConstant/ShippingConstants";
import * as TextConfig from "../AppConstant/TextConfig";

const LoginNew = (props) => {
  const dispatch = useDispatch();
  const reduxLogin = useSelector((state) => state.userReducer.isLoggedIn);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [userName, setUserName] = useState("");
  const [userNameValidation, setUserNameValidation] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState(false);
  const [password, setPassword] = useState("");
  const [spinner, setSpinner] = useState(false);

  const [loginError, setLoginError] = useState("");
  const [showLoginError, setShowLoginError] = useState(false);

  const referer = GetReferer(props);

  function GetReferer(props) {
    return props.location?.state?.referer || "/";
  }

  useEffect(() => {
    if (sessionStorage.getItem("sessionExpired") === "true") {
      displayError("session expired, please login", true);
      sessionStorage.removeItem("sessionExpired");
    }
  }, [reduxLogin]);

  if (isLoggedIn) {
    console.log("LoginNew referer", referer);
    return <Navigate to={referer} />;
  }

  const userNameFocusHandler = () => {
    setUserNameValidation(false);
    setShowLoginError(false);
  };
  const passwordFocusHandler = () => {
    setPasswordValidation(false);
    setShowLoginError(false);
  };

  const login = (event) => {
    event.preventDefault();

    if (!validateCredentials()) return false;

    setSpinner(true);

    LoginService.Authenticate(userName, password).then((response) => {
      setSpinner(false);
      if (response.status === 200) {
        //initialize redux store location, courier here
        dispatch(allActions.userActions.setUser(response.data));
        dispatch(allActions.shipmentActions.setLocation(shippingConst.FROM_ADDRESS[TextConfig.ALL_LOCATIONS].code));
        dispatch(allActions.shipmentActions.setCourier(shippingConst.COURIER_SERVICES_SELECTED));

        sessionStorage.setItem("user", JSON.stringify(response.data));
        sessionStorage.setItem("AuthToken", response.data.token);
        setLoggedIn(true);
      } else if (response.status === 400) {
        displayError(response.data["message"], true);
      } else {
        displayError(response.message, true);
      }
    });
  };

  //TODO:Nevin: can this be moved to servicelayer
  const validateCredentials = () => {
    console.log("inside validateCredentials");
    if (userName === "") {
      console.log("inside validateCredentials userName");
      setUserNameValidation(true);
      return false;
    } else if (password === "") {
      console.log("inside validateCredentials password");
      setPasswordValidation(true);
      return false;
    } else {
      return true;
    }
  };

  const displayError = (errorMessage, showError) => {
    setLoginError(errorMessage);
    setShowLoginError(showError);
  };

  return (
    <Fragment>
      <div className="container loginBody">
        <div className="card card-container">
          <img id="profile-img" className="profile-img-card" alt="logo" src={loginlogo} />
          <p id="profile-name" className="profile-name-card"></p>
          <Fragment>
            <form className="form-signin">
              <span id="reauth-email" className="reauth-email"></span>
              <div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="user id "
                  value={userName}
                  onFocus={userNameFocusHandler}
                  onChange={(e) => {
                    setUserName(e.target.value);
                  }}
                  required
                ></input>
                {userNameValidation && <div className="badge badge-danger">Please enter username.</div>}
              </div>
              <div>
                <input
                  type="password"
                  className="form-control"
                  placeholder="password"
                  required
                  value={password}
                  onFocus={passwordFocusHandler}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                ></input>
                {passwordValidation && <div className="badge badge-danger">Please enter password.</div>}
              </div>
              <button type="submit" className="btn btn-lg btn-dark btn-block btn-signin" onClick={login}>
                {spinner ? (
                  <Fragment>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Signing in ...
                  </Fragment>
                ) : (
                  "Sign in"
                )}
              </button>
              {showLoginError && (
                <div className="alert alert-custom" role="alert">
                  {loginError}
                </div>
              )}
            </form>
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

export default LoginNew;
