const config = {
  local: {
    apiRoot: "https://localhost:5001",
  },
  dev: {
    apiRoot: "https://dev-shipping-api.palloys.com",
  },
  uat: {
    apiRoot: "https://uat-shipping-api.palloys.com",
  },
  prod: {
    apiRoot: "https://shipping-api.palloys.com",
  },
};

const getConfig = () => {
  console.log(`process.env.REACT_APP_ENV_CONFIG: ${process.env.REACT_APP_ENV_CONFIG}`);
  const whichConfig = process.env.REACT_APP_ENV_CONFIG || "dev";
  return config[whichConfig];
};

export { getConfig };
